import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {inject} from "aurelia-framework";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {BasicForm} from "../../resources/elements/BasicForm";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../resources/services/ConfigService";
import {PatientService} from "resources/services/PatientService";
import {LocationService} from "resources/services/LocationService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";

@inject(I18N, PatientService, LocationService)
export class Pflegeprozess {
    get patient(): PatientItem {
        return PatientItem.SelectedPatient;
    }

    patientService: PatientService;
    encounterId: string;
    isLoading: boolean;
    config: IFormSetting;
    i18n: I18N;
    locationService: LocationService;

    constructor(i18n: I18N, patientService: PatientService, locationService: LocationService) {
        this.i18n = i18n;
        this.patientService = patientService;
        this.locationService = locationService;
    }

    activate(params) {
        this.encounterId = params.id;
    }

    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.CareProcess);
        if (this.config) BasicForm.pageTitle = this.i18n.tr(this.config.title);

        if (!this.encounterId) {
            return;
        }

        this.isLoading = true;
        await this.locationService.fetch();

        PatientItem.Load(this.encounterId)
            .then(() => {
                this.isLoading = false;
                // set the App.currentWardId from the encounter.
                // this is needed when running embedded and user wants to access BedIT
                if (this.patient && this.patient.encounter && this.patient.encounter.location) {
                    for (let i = 0; i < this.patient.encounter.location.length; i++) {
                        if (this.patient.encounter.location[i].location) {
                            let ref = this.patient.encounter.location[i].location.reference;
                            if (ref) {
                                let ward = this.locationService.getLocationById(ref);
                                if (ward) {
                                    RuntimeInfo.CurrentWardId = ward.id;
                                }
                            }
                        }
                    }
                }
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
