import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {PatientService} from "../../resources/services/PatientService";
import {QuestionnaireResponse} from "../../resources/classes/FhirModules/Fhir";

export class QuestionnaireDefault extends FormBaseClass {
    tmpResponseId : string;

    async activate(params) {
        /* HOTFIX for Presentation with Med-Board: */
        if (params?.responseId) {
            const response = await this.fhirService.get(`QuestionnaireResponse/${params.responseId}`);
            if (response) {
                PatientService.AddQuestionnaireResponse(this.patient, response, true);
            } else {
                console.warn(`No response with id ${params.responseId} found on the server!`);
            }
        }

        await super.activate(params);
        this.questionnaireName = params.name;
        this.showSaveButton = true;
        if (!this._buttonText) this._buttonText = "add_document";
    }

    override async loadData() :Promise<void> {
        this.reAssignResponses = true;
        await super.loadData();

        const prevs = this.response ? this.patient.questionnaireResponses
            .filter(o=>o.questionnaire === this.response.questionnaire)
            .filter(o=>o.id !== this.response.id)
            .filter(o=>['completed', 'amended'].indexOf(o.status) > -1) : [];

        if (this.response) {
            const prev = prevs.length > 0 ? prevs[prevs.length - 1] : undefined;
            if (prev) this.previousResponse = prev;

            QuestionnaireResponse.SetDefaultValues(this.response, prev);
        }
    }

}
