import {bindable, autoinject} from "aurelia-framework";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";

const moment = require("moment");
import {BasicForm} from "../../../resources/elements/BasicForm";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../../resources/services/ConfigService";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {PatientService} from "resources/services/PatientService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {FhirService} from "../../../resources/services/FhirService";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {QuestionnaireResponse} from "../../../resources/classes/FhirModules/Fhir";
import {NitTools} from "../../../resources/classes/NursitTools";

@autoinject
export class evaluation {
    encounterId;
    targets: any[] = [];
    patient: PatientItem;
    qList: IQuestionnaireList;
    @bindable currentAssessment;
    assessments: any[] = [];
    allAssessments: any[] = [];
    @bindable showOverdued: boolean = true;
    @bindable showInTime: boolean = true;
    @bindable showSolved: boolean = false;

    currentAssessmentChanged() {
        this.gatherData();
    }

    showSolvedChanged() {
        this.gatherData();
    }

    showOverduedChanged() {
        this.gatherData();
    }

    showInTimeChanged() {
        this.gatherData();
    }

    currentTargets;

    gatherData() {
        if (!this.currentAssessment) return;
        const tmp = [];
        (<any>this.currentAssessment).targets.forEach(a => {
            if (a.solved && this.showSolved) tmp.push(a);
            else if (!a.solved && a.inTime && this.showInTime) tmp.push(a);
            else if (!a.solved && !a.inTime && this.showOverdued) tmp.push(a);
        })

        this.currentTargets = NitTools.Clone(tmp);
    }

    constructor(protected questionnaireService: QuestionnaireService, protected notifier: PatientChangeNotifier, protected i18n: I18N, protected patientService: PatientService, protected fhirService: FhirService) {
    }

    activate(params) {
        this.encounterId = params.id;
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
    }

    async attached() {
        if (ConfigService.Debug) {
            window["evaluation"] = this;
        }

        let cfg = ConfigService.GetFormSettings(ConfigService.FormNames.Evaluation);
        if (cfg) {
            BasicForm.pageTitle = this.i18n.tr(cfg.title);
        }

        RuntimeInfo.IsLoading = true;
        try {
            document.body.classList.add("no-toolbar-window");
            this.qList = await QuestionnaireService.GetQuestionnaireIds();
            this.patient = await PatientItem.Load(this.encounterId)
            this.notifier.notify(this.patient);

            await this.loadAllAssessments();
            // this.gatherData();
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async getTargetedItemsFromResponse(response: any): Promise<any[]> {
        let result = [];
        let questionnaire = await this.questionnaireService.getQuestionnaireById(response.questionnaire.reference);
        let itemIDs = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire);
        for (let i = 0; i < itemIDs.length; i++) {
            let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, itemIDs[i], false);
            let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, itemIDs[i]);
            if (responseItem && responseItem.extension) {
                let targetDateEx = Fhir.Tools.GetOrCreateExtension(responseItem, 'target-date', false);
                let targetValueEx = Fhir.Tools.GetOrCreateExtension(responseItem, 'target-value', false);
                if (targetDateEx && targetValueEx && questionnaireItem) {
                    let targetDate = moment(new Date(Fhir.Tools.GetExtensionValue(targetDateEx)));
                    const item = {
                        linkId: responseItem.linkId,
                        text: questionnaireItem.text,
                        date: targetDate.toJSON(),
                        targetValue: Fhir.Tools.GetExtensionValue(targetValueEx),
                        currentValue: Fhir.QuestionnaireResponse.GetResponseItemValue(responseItem),
                        questionnaireItem: questionnaireItem,
                        responseItem: responseItem,
                        solved: false,
                        targetText: '',
                        currentText: '',
                        inTime: true
                    };

                    item.solved = item.currentValue === item.targetValue;
                    item.inTime = targetDate.isAfter(new Date());

                    let sollOption = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === item.targetValue);
                    let istOption = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === item.currentValue);
                    item.targetText = sollOption && sollOption.valueCoding ? sollOption.valueCoding.display : '?';
                    item.currentText = istOption && istOption.valueCoding ? istOption.valueCoding.display : '?';

                    result.push(item);
                }
            }
        }

        return result;
    }

    async loadAllAssessments() {
        this.allAssessments = [];
        const _assessments = this.questionnaireService.getResponsesOfType(this.patient, this.qList.QAssessmentId);
        for (const a of _assessments) {
            const targetItems = await this.getTargetedItemsFromResponse(a);
            let solvedCount = targetItems.filter(o => o.solved === true).length;
            this.allAssessments.push({
                id: a.id,
                date: new Date(a.authored),
                targets: targetItems || [],
                solvedCount: solvedCount,
                status: a.status
            })
        }

        this.allAssessments.reverse();
        this.currentAssessment = this.allAssessments[0];
    }
}
