import {bindable, TaskQueue} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../../../resources/classes/translations";
import {NitTools} from "../../../resources/classes/NursitTools";
import * as environment from "../../../../config/environment.json";
import {woundDraw} from "../draw";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {FhirService} from "../../../resources/services/FhirService";

export class woundImageDialog {
    static inject = [DialogController, DialogService, TaskQueue, DialogMessages, I18N, FhirService];
    @bindable title: string;
    controller: DialogController;
    service: DialogService;
    showDraw: boolean;
    options: IWoundPromptOptions;
    taskQueue: TaskQueue;
    hasDrawingObjects: boolean = false;
    imageOriginal;
    imageEdited;
    viewingOriginal: boolean = false;
    imageSource;
    dialogMessages: DialogMessages;
    i18n: I18N;

    public get debugInfo(): string {
        return woundImageDialog.debugInfo;
    }

    public static debugInfo: string = "";

    printId: string;
    fhirService: FhirService;

    constructor(controller, service, taskQueue: TaskQueue, dialogMessages: DialogMessages, i18n, fhirService) {
        this.controller = controller;
        this.service = service;
        this.taskQueue = taskQueue;
        this.controller.settings.centerHorizontalOnly = false;
        this.showDraw = RuntimeInfo.Features.draw;
        this.printId = `${NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition)}PrintImage`;
        this.dialogMessages = dialogMessages;
        this.i18n = i18n;
        this.fhirService = fhirService;
    }

    thumb;

    async closeDialog() {
        NitTools.enablePinchZoom();

        await this.updatePrintValueOnFhir();
        await this.controller.close(true, this.thumb ? this.thumb.resource : null);
    }

    thumbId: string;
    mediaId: string;

    async activate(options: IWoundPromptOptions) {
        if (options.thumbnail && options.thumbnail.resource) {
            this.thumbId = options.thumbnail.resource.id;
        }

        if (options.thumbnail && options.thumbnail.resource && options.thumbnail.resource.identifier) {
            let mediaIdIdentifier = options.thumbnail.resource.identifier.find(o => o.system.endsWith('media-source-id'));
            if (mediaIdIdentifier) {
                this.mediaId = mediaIdIdentifier.value;
            }
        }

        document.body.style.setProperty('zoom', '0.999');
        NitTools.disablePinchZoom();
        this.title = options.title || translations.translate("information");
        this.imageOriginal = "data:" + options.media.content.contentType + ";base64," + options.imageSource;
        this.imageSource = this.imageOriginal;
        this.media = options.media;
        this.thumb = options.thumbnail;

        try {
            if (options && options.drawingObjects && (<any>options.drawingObjects).drawingObjects)
                options.drawingObjects = (<any>options.drawingObjects).drawingObjects;
        } catch (ex) {
            console.warn(ex);
        }

        this.options = options;

        this.hasDrawingObjects = RuntimeInfo.Features.draw
            && this.options && this.options.drawingObjects && this.options.drawingObjects.length > 0;

        if (typeof options.showDraw === "boolean")
            this.showDraw = options.showDraw;

        if (!this.media.identifier) this.media.identifier = [];
        if (this.thumb && this.thumb.resource && !this.thumb.resource.identifier) this.thumb.resource.identifier = [];

        const printTag = this.fhirService.tags.get(this.media, "/PrintImage");
        if (printTag) {
            this.originalPrint = printTag.code === "true";
            this.printImage = this.originalPrint;
        }
    }

    originalPrint: boolean;

    @bindable printImage: boolean;

    deleteImage() {
        this.dialogMessages.dialog("Wirklich dieses Bild löschen?", this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"))
            .whenClosed(result => {
                if (result.wasCancelled) return;
                RuntimeInfo.IsLoading = true;
                try {
                    Fhir.Rest.Delete(this.media)
                        .then(() => Fhir.Rest.Delete(this.thumb.resource));
                } catch (e) {
                    console.warn(e);
                } finally {
                    if (typeof this.options.onDeleted === "function") {
                        this.options.onDeleted();
                    }

                    RuntimeInfo.IsLoading = false;
                    this.controller.ok({method: "delete"});
                }
            })
    }

    async updatePrintValueOnFhir() {
        RuntimeInfo.IsLoading = true;

        try {
            if (!this.media || this.originalPrint === this.printImage) return;

            // remove on Thumb
            const printCoding: any = {
                system: NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition) + '/PrintImage',
                code: this.printImage ? "true" : "false"
            };

            await this.fhirService.tags.update(this.media, printCoding);
            await this.fhirService.tags.update(this.thumb.resource, printCoding);

            // await this.setPrintIdentifier(this.media);
            // await this.setPrintIdentifier(this.thumb.resource);
            this.thumb.print = this.printImage;
            // update on server
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async toggleSource() {
        if (!this.hasDrawingObjects) return;
        if (this.viewingOriginal) {
            if (!this.imageEdited) this.imageEdited = await this.applyDrawing();
            this.imageSource = this.imageEdited;
            this.viewingOriginal = false;
        } else {
            this.imageSource = this.imageOriginal;
            this.viewingOriginal = true;
        }

        let img = <HTMLImageElement>document.querySelector("img.viewer-move");
        if (img) {
            img.src = this.imageSource;
        }
    }

    async attached() {
        await this.applyDrawing();
    }

    media: any;

    applyDrawing(): Promise<string> {
        if (!this.options || !this.options.drawingObjects || !this.options.imageSource) return;

        let div = document.querySelector(".wound-dialog-touch-image-container");
        let cw = div.clientWidth;
        let ch = div.clientHeight;

        let cnv: HTMLCanvasElement = document.createElement("canvas");
        let img: HTMLImageElement = document.createElement("img");
        img.onload = () => {
            cnv.width = img.width || img.naturalWidth;
            cnv.height = img.height || img.naturalHeight;
            woundDraw.zoom = 1 / Math.max(cnv.width / cw, cnv.height / ch);

            let ctx = cnv.getContext("2d");
            ctx.drawImage(img, 0, 0);

            if (this.options.drawingObjects["drawingObjects"])
                this.options.drawingObjects = this.options.drawingObjects["drawingObjects"];

            if (this.options && this.options.drawingObjects && NitTools.IsArray(this.options.drawingObjects)) {
                this.options.drawingObjects.forEach(obj =>
                    woundDraw.updateCanvas(ctx, obj)
                );
            }

            this.imageEdited = cnv.toDataURL("image/jpeg", 100);
            this.imageSource = this.imageEdited;
            this.viewingOriginal = false;

            return Promise.resolve(this.imageEdited);
        };

        img.src = "data:" + this.options.media.content.contentType + ";base64," + this.options.imageSource;
    }

    displayImage: HTMLImageElement;

    openDraw() {
        if (this.options.onOpenDraw) this.options.onOpenDraw(this.options.mediaId);
        else alert("onOpenDraw nicht zugewiesen");
        window["__tempMedia"] = this.media;
        this.closeDialog();
    }
}

export interface IWoundPromptOptions {
    imageSource: string;
    title: string;
    mediaId: string;
    onOpenDraw?: Function;
    drawingObjects?: any[];
    media: any;
    showDraw;
    thumbnail;
    onDeleted?: Function;
}
