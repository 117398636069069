import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {translations} from "../../resources/classes/translations";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import {ConfigService} from "../../resources/services/ConfigService";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {NitTools} from "../../resources/classes/NursitTools";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";

export class patientVerlegung extends FormBaseClass {
    async attached() {
        this.route = ConfigService.FormNames.Transfer;
        await super.attached();
        this.buttonText = translations.translate("new_verlegung");
    }

    afterCreate(result: any) {
        super.afterCreate(result);
        ConfigService.LoadConfigOverride(this.patient?.ward, this.patient)
            .then(() => {
                this.createVerlegungMapping(result)
                    .then(() => {
                        this.fhirService.update(result)
                            .then((result: any) => {
                                this.patientService.addQuestionnaireResponse(this.patient, result, true);
                                this.hasChanges = false;
                            })
                    })
            })
            .catch(err => {
                console.warn(err.message||err);
            })
    }

    afterLoadedData() {
        super.afterLoadedData();
        if (!this.patient) return;
        if (!this.response) {
            this.response = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QVerlegungId, []);
        }

        if (!this.response) return;
        if (!this.response.item || this.response.item.length === 0) this.createVerlegungMapping(this.response);
    }

    debug(message: string) {
        if (ConfigService.Debug) {
            console.debug(`[Discharge] ${message}`);
        }
    }

    info(message: string) {
        if (ConfigService.Debug) {
            console.info(`[info] ${message}`);
        }
    }

    createVerlegungMapping(response): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            response = NitTools.Clone(response);
            this.response = undefined;

            if (this.mappings.length > 0 && this.valueMappings.length > 0) {
                this.taskQueue.queueTask(() => {
                    this.response = patientVerlegung.executeCreateVerlegungMapping(response, this.patient);
                    this.hasChanges = true;
                    resolve();
                });
            } else {
                new HttpClient().createRequest("config/verlegung-mapping.json")
                    .asGet()
                    .send()
                    .then((result: HttpResponseMessage) => {
                        let obj = JSON.parse(result.response);
                        this.mappings = obj["mappings"];
                        this.valueMappings = obj["valueMappings"];
                        this.taskQueue.queueTask(() => {
                            this.response = patientVerlegung.executeCreateVerlegungMapping(response, this.patient);
                            this.hasChanges = true;
                            resolve();
                        });
                    })
                    .catch(error => {
                        console.warn("When reading mappings-file: ", error.message || JSON.stringify(error));
                        reject(error.message || JSON.stringify(error));
                    })
            }
        })
    }

    static executeCreateVerlegungMapping(response: any, patient: PatientItem): any {
        response = NitTools.Clone(response);

        if (!response) {
            if (ConfigService.Debug)
                console.debug("No response present, exiting");

            return;
        }

        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
        if (!response.item) response.item = [];
        if (response.status === "amended" || response.status === "completed") {
            // do it this way, because there may be responses without status
            if (ConfigService.Debug)
                console.debug(`Response has not right state (${response.status}). Exiting.`);
            return;
        }

        let assessment: any = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let anamnese: any = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let discharge: any = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.__listResult.QDischargeManagementId, [fhirEnums.QuestionnaireResponseStatus.inProgress, fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

        this.__mappings.forEach((mapping) => {
            let questionnaireField: any = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, mapping.verlegung);
            let targetItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, mapping.verlegung, true);

            let sourceItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, mapping.source, false);

            if (!sourceItem) {
                sourceItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnese, mapping.source, false);
            }

            if (!sourceItem) {
                sourceItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(patient.selectedAdditionalInfo, mapping.source, false);
            }

            if (!sourceItem) {
                sourceItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(discharge, mapping.source, false);
            }

            if (sourceItem) {
                let newValue = Fhir.QuestionnaireResponse.GetResponseItemValue(sourceItem);
                if (newValue) {
                    let valueMapping = this.__valueMappings.find(o => o.source === newValue);
                    if (valueMapping) {
                        newValue = valueMapping.verlegung;
                    }

                    if (questionnaireField && questionnaireField.type) {
                        if (questionnaireField.type === "string") {
                            if (sourceItem.answer) {
                                if (sourceItem.answer.length === 0) {
                                    if (sourceItem.answer[0] && sourceItem.answer[0].valueCoding && sourceItem.answer[0].valueCoding.display) {
                                        newValue = sourceItem.answer[0].valueCoding.display;
                                    }
                                } else {
                                    let vals = [];
                                    sourceItem.answer.forEach(a => {
                                        if (a.valueCoding && a.valueCoding.display)
                                            vals.push(a.valueCoding.display);
                                        else
                                            vals.push(Fhir.QuestionnaireResponse.GetItemAnswer(a, ''));
                                    });

                                    vals = vals.filter(o => o);
                                    newValue = vals.join('\r\n');
                                }

                                // Fhir.QuestionnaireResponse.SetResponseItemValue(targetItem, newValue, newValue);
                                targetItem.answer = [{valueString: newValue}];
                            }
                        } else {
                            targetItem.answer = [{
                                valueCoding: {
                                    code: newValue
                                }
                            }];
                        }

                        if (ConfigService.Debug)
                            console.debug(`Verlegung: mapped from field ${sourceItem.linkId} ("${Fhir.QuestionnaireResponse.GetResponseItemValue(sourceItem)}") to Verlegung.${targetItem.linkId} ("${newValue}"). Title: ${questionnaireField.text}`);
                    } else {
                        Fhir.QuestionnaireResponse.SetResponseItemCoding(targetItem, newValue, sourceItem && sourceItem.answer && sourceItem.answer[0] && sourceItem.answer[0].valueCoding && sourceItem.answer[0].valueCoding.display ? sourceItem.answer[0].valueCoding.display : newValue);
                    }
                } else {
                    if (ConfigService.Debug)
                        console.debug("no sourcevalue in " + sourceItem.linkId);
                }
            } else {
                if (ConfigService.Debug)
                    console.debug("No sourceItem for field " + mapping.verlegung + " found");
            }
        });

        return response;
    }

    static __mappings: { "verlegung": string, "source": string }[] = [];

    get mappings(): { "verlegung": string, "source": string }[] {
        return patientVerlegung.__mappings;
    }

    set mappings(newValue: { "verlegung": string, "source": string }[]) {
        patientVerlegung.__mappings = newValue;
    }

    static __valueMappings: { "verlegung": string, "source": string }[] = [];

    get valueMappings(): { "verlegung": string, "source": string }[] {
        return patientVerlegung.__valueMappings;
    }

    set valueMappings(newValue: { "verlegung": string, "source": string }[]) {
        patientVerlegung.__valueMappings = newValue;
    }
}
