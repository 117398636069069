import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {inject} from "aurelia-framework";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {BasicForm} from "../../resources/elements/BasicForm";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../resources/services/ConfigService";
import {PatientService} from "resources/services/PatientService";
import {LocationService} from "resources/services/LocationService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {IdLogikService} from "../../resources/services/IdLogikService";
import {UserService} from "../../resources/services/UserService";
import {FhirService} from "../../resources/services/FhirService";
import * as environment from "../../../config/environment.json";

@inject(I18N, PatientService, LocationService, IdLogikService, UserService)
export class Medications {
    patientService: PatientService;
    patient: PatientItem;
    encounterId: string;
    isLoading: boolean;
    config: IFormSetting;
    i18n: I18N;
    locationService: LocationService;
    idLogikService: IdLogikService;
    idLogikMedicationIframe;
    userService: UserService;
    iframeUrl = ''

    constructor(i18n: I18N, patientService: PatientService, locationService, idLogikService: IdLogikService, userService: UserService) {
        this.i18n = i18n;
        this.patientService = patientService;
        this.locationService = locationService;
        this.idLogikService = idLogikService;
        this.userService = userService;
    }

    get isIdLogikMedicationEnabled() {
        return IdLogikService.configEnabled;
    }

    activate(params) {
        this.encounterId = params.id;
    }

    async attached() {
        document.body.classList.add("no-toolbar-window");

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Medications);
        BasicForm.pageTitle = this.i18n.tr(this.config.title);

        if (!this.encounterId) {
            this.patient = undefined;
            RuntimeInfo.CurrentWardId = undefined;
            return;
        }

        this.isLoading = true;
        await this.locationService.fetch();

        try {
            this.patient = await this.patientService.fetch(this.encounterId);
            this.isLoading = false;
            // set the App.currentWardId from the encounter.
            // this is needed when running embedded and user wants to access BedIT
            if (this.patient.encounter) {
                for (let i = 0; i < this.patient.encounter.location.length; i++) {
                    if (this.patient.encounter.location[i].location) {
                        let ref = this.patient.encounter.location[i].location.reference;
                        if (ref) {
                            let ward = this.locationService.getLocationById(ref);
                            if (ward) {
                                RuntimeInfo.CurrentWardId = ward.id;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.warn(error.message || error);
            this.isLoading = false;
        }

        const loginData = sessionStorage.getItem(environment.sessionName);

        const query = {
            login: loginData,
            server: FhirService.Endpoint,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: '',
            practitionerName: '',
            fhirVersion: FhirService.FhirVersion,
        };

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
            query.practitionerName = this.userService.fullNameOrUsername
        }

        this.iframeUrl = `${this.config && this.config.settings && this.config.settings.iframeUrl}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`
        }).join('&')

        if (this.isIdLogikMedicationEnabled) {
            this.idLogikService.attach(this.idLogikMedicationIframe, this.encounterId);
        }
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
    }
}
