import {Router} from "aurelia-router";

const moment = require("moment");
import {translations} from "resources/classes/translations";
import {inject} from "aurelia-framework";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {LOINC} from "../../resources/classes/Codes";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import {ModalShiftReport} from "../../resources/elements/modal-shift-report";
import {DialogService} from "aurelia-dialog";
import {Prompt} from "../../resources/elements/prompt";
import {ModalShiftReportHistory} from "../../resources/elements/modal-shift-report-history";
import {NitTools} from "../../resources/classes/NursitTools";
import {FhirService} from "../../resources/services/FhirService";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {BasicForm} from "../../resources/elements/BasicForm";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../resources/services/ConfigService";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {PatientService} from "resources/services/PatientService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {ReportService} from "resources/services/ReportService";
import {DialogMessages} from "resources/services/DialogMessages";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {GrafixxItem, IGrafixxItem} from "../../resources/classes/Grafixx-item";
import {WoundGroup, WoundGroupItem} from "./wunden/wound-main";
import {UserService} from "../../resources/services/UserService";
import jqXHR = JQuery.jqXHR;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {HttpClient} from "aurelia-http-client";

/** this is the PflegeAkte/CareFiles View. Setting-Node is RuntimeInfo.Forms.find(o=>o.route === "carefiles") */
@inject(PatientChangeNotifier, DialogService, FhirService, I18N, PatientService, QuestionnaireService, DialogMessages, Router)
export class PatientPflegeakte {
    private _settings: IFormSetting = undefined;
    showingReportSelection: boolean = false;
    config: IFormSetting;
    qList: IQuestionnaireList;
    responses: any[];
    loadingWounds: boolean = false;
    patientService: PatientService;
    dialogMessages: DialogMessages;
    encounterId: string = undefined;
    patient: PatientItem;
    dialogService;
    viewMode: PAViewMode = PAViewMode.akte;
    fhirService: FhirService;
    showPrintButton: boolean = false;
    questionnaireService: QuestionnaireService;
    settingName: string = "carefiles";
    showPrint: boolean = false;
    showDischargeExportButton: boolean = false;
    mdkAccordionItems: any[];
    printableReports: any[] = [];
    progressReports = [];
    mdkData: MDKData = new MDKData();
    selectedReponseId: string = undefined;
    additionalReports: any[] = [];
    woundItems: any[];
    totalWoundsCount: number = 0;
    woundResponseListEntries: any[] = [];
    woundSettings: IFormSetting;
    toLoad: string[] = [];
    showNameIcon: boolean = false;
    displayWounds: boolean = true;
    allowEditProgressReportItems : boolean = true;

    // default value for controlling report/button top left
    controllingItem = {
        "enabled": true,
        "report": "controlling_sempa.frx",
        "title": this.i18n.tr("controlling"),
        "isMainMenuButton": true
    }

    get isDebug(): boolean {
        return ConfigService.Debug;
    }

    constructor(protected notifier: PatientChangeNotifier, dialogService, fhirService: FhirService,
                protected i18n: I18N, patientService: PatientService, questionnaireService: QuestionnaireService, dialogMessages: DialogMessages,
                protected router: Router) {
        this.patientService = patientService;
        this.dialogService = dialogService;
        this.fhirService = fhirService;
        this.questionnaireService = questionnaireService;
        this.dialogMessages = dialogMessages;
    }

    async attached() {
        try {
            this.loadingWounds = true;
            RuntimeInfo.IsLoading = true;
            document.body.classList.add('no-toolbar-window', 'force-no-scrollbar');

            await this.questionnaireService.fetch();
            this.responses = await this.fhirService.fetch(`QuestionnaireResponse?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.encounterId}`)

            this.config = ConfigService.GetFormSettings(this.settingName);
            if (this.config) {
                BasicForm.pageTitle = this.i18n.tr(this.config.title);

                if (!this.config.settings) this.config.settings = {};
                if (!this.config.settings.authorDisplay) this.config.settings.authorDisplay = 'none'; // set default to none, to avoid loading if possible

                if (typeof this.config.settings?.showDischargeExportButton === "boolean") {
                    this.showDischargeExportButton = this.config.settings?.showDischargeExportButton;
                }
            }

            this.qList = await this.questionnaireService.getQuestionnaireIds();
            await this.loadPatient();

            this.createMdkAccordions();
            await this.mdkAccordionItems.forEach(async mdk => await this.getMdkItemsFor(mdk));
            this.showPrintButton = !!ReportService.ReportServer;
        } catch (e) {
            console.warn(e);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    detached() {
        document.body.classList.remove('no-toolbar-window', 'force-no-scrollbar');
        this.woundItems = undefined;
        this.woundResponseListEntries = undefined;
    }

    activate(params) {
        this.encounterId = params.id;
        this.mdkData.encounterId = this.encounterId;

        if (ConfigService.Debug) window['carefiles'] = this;
    }

    /**
     * issues a call to the discharge api and gives the user the result as a messagebox
     */
    async callDischargeApi() {
        if (!this.showDischargeExportButton) return;
        try {
            RuntimeInfo.IsLoading = true;
            //callDischargeApi
            // const url = `${NitTools.ExcludeTrailingSlash(ReportService.ReportServer)}/api/export/discharge/${this.patient.encounterId}`;
            const client = new HttpClient();
            const request = client.createRequest(`api/export/discharge/${this.patient.encounterId}`).withBaseUrl(ReportService.ReportServer).asGet()

            const result = await request.send();
            console.warn(result.response);

            const response = JSON.parse(result.response || '{ "success": false }');
            let msg : string;
            if (!response.success) {
                msg = this.i18n.tr("dischargeExportHadError") + response.error || 'No further informations';
            } else {
                if (response.items) {
                    let messages = [];
                    for (const err of response.items.filter(o=>o.success === false && o.reportName)) {
                        let report : string = err.reportName.replace('.frx', '').replace('.FRX', '').replace('.Frx', '');
                        let error : string = String(err.message).trim();
                        if (error.indexOf(', for EncounterId:') > -1) {
                            error = error.split(', for EncounterId:')[0].trim();
                        }
                        // No QuestionnaireResponse, for Report "Stammblatt.frx", for Questionnaire:"CareITAnamnesis"
                        if (error.startsWith('No QuestionnaireResponse,') && error.indexOf(', for Report') > -1 && error.indexOf(', for Questionnaire') > -1) {
                            let [info, _, questionnaire] = error.split(',');
                            questionnaire = questionnaire.replace('for Questionnaire', '').replace('"', '').replace(':','').trim();
                            info = info.trim();
                            error = `${info} for "${questionnaire}"`;
                        }

                        messages.push(`<b>- ${report}</b>: <span style="color: darkorange">${error}</span>`);
                    }

                    if (messages.length > 0) {
                        messages = [this.i18n.tr('dischargeExportItemErrors'), ...messages];
                        msg = messages.join('<br />');
                    }
                }
            }

            RuntimeInfo.IsLoading = false;
            if (msg) {
                this.dialogMessages.prompt(msg, this.i18n.tr('warning'), true);
            } else {
                this.dialogMessages.prompt(this.i18n.tr('dischargeExportSuccess'), this.i18n.tr('information'), false);
            }
        }
        catch (e) {
            console.warn(e);
            RuntimeInfo.IsLoading = false;
        }
    }

    printResponsesForMdk() {
        alert("Feature is currently under development. Please be patient.")
    }

    printMdk(mdk) {
        if (ConfigService.Debug)
            console.debug("[Pflegeakte::prinktMdk]:", mdk);
    }

    getMdkItemsFor(mdk): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!mdk) {
                reject("No mdk given to process");
                return;
            }

            mdk.items = [];
            mdk.id = NitTools.UidName();

            if (mdk.type === PAItemType.questionnaireResponse && mdk.questionnaireName) {
                this.questionnaireService.getQuestionnaireByName(mdk.questionnaireName)
                    .then(questionnaire => {
                        mdk.id = questionnaire.id;

                        let url = `QuestionnaireResponse?questionnaire=${QuestionnaireService.GetQuestionnaireQueryUrl(questionnaire)}&${FhirService.FhirVersion >= 4 ? 'encounter' : 'context'}=Encounter/${this.encounterId}`;

                        this.fhirService.fetch(url)
                            .then(async (result: any[]) => {
                                result.sort((a, b) => {
                                    let dA = new Date(a.authored);
                                    let dB = new Date(b.authored);
                                    return dA.valueOf() - dB.valueOf();
                                });

                                result.forEach((r: any) => {
                                    r.text = {
                                        div: `<div>${moment(r.authored).format(RuntimeInfo.DateTimeFormat)}</div>`,
                                        status: "generated"
                                    };

                                    //#region add Barthel index value if it is BI
                                    if (questionnaire.id === this.qList.QBarthelIndexId) {
                                        let bi11 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(r, "BI_11", false);
                                        if (bi11) {
                                            let val = Fhir.QuestionnaireResponse.GetResponseItemValueInt(bi11, 0);
                                            if (val) {
                                                r.text.div += " (" + val.toString() + ")";
                                            }
                                        }
                                    }
                                    //#endregion

                                    //#region add Isolation reason if it's isolation
                                    if (questionnaire.id === this.qList.QIsolationId) {
                                        let iso06 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(r, "iso_06", false);
                                        if (iso06 && iso06.text) {
                                            r.text.div += " [" + iso06.text + "]";
                                        }
                                    }
                                    //#endregion

                                    // save the response id to global generate directory
                                    // map from questionnaire-id to report-name
                                    /* let report = "";
                                    switch (questionnaire.id) {
                                        case QuestionnaireService.__listResult.QAssessmentId:
                                            report = enviro nment.reports.assessment;
                                            break;
                                        case QuestionnaireService.__listResult.QAnamnesisId:
                                            report = environ ment.reports.anamnesis;
                                            break;
                                        case QuestionnaireService.__listResult.QIsolationId:
                                            report = environm ent.reports.isolation;
                                            break;
                                        case QuestionnaireService.__listResult.QBarthelIndexId:
                                            report = enviro nment.reports.isolation;
                                            break;
                                        case QuestionnaireService.__listResult.QFallId:
                                            report = environ ment.reports.fall;
                                            break;
                                        case QuestionnaireService.__listResult.QVerlegungId:
                                            report = environ ment.reports.verlegung;
                                            break;
                                        case QuestionnaireService.__listResult.QBarthelIndexExId:
                                            report = environ ment.reports.barthelEx;
                                            break;
                                        case QuestionnaireService.__listResult.QDischargeManagementId:
                                            report = environm ent.reports.discharge;
                                            break;
                                        case QuestionnaireService.__listResult.QWoundMasterId:
                                        case QuestionnaireService.__listResult.QWoundChildId:
                                        case QuestionnaireService.__listResult.QWoundId:
                                            report = envir onment.reports.wound;
                                            break;
                                        case QuestionnaireService.__listResult.QDiagnosisId:
                                            report = enviro nment.reports.diagnosis;
                                            break;
                                    } */

                                    if (mdk.report) {
                                        let _r = this.mdkData.reports.find(o => o.name === mdk.report);
                                        if (!_r) {
                                            _r = {
                                                name: mdk.report,
                                                responses: []
                                            };

                                            this.mdkData.reports.push(_r);
                                        }

                                        _r.responses.push({questionnaireResponseId: r.id, svgImage: ""});
                                    }

                                });

                                mdk.items = result;
                                resolve();
                            })
                    });
            }
        });
    };

    async checkFormSetting() {
        if (!this._settings)
            this._settings = ConfigService.GetFormSettings("carefiles");

        return this._settings;
    }

    async getSortOrder() {
        let form: IFormSetting = await this.checkFormSetting();
        if (!form || !form.settings || !form.settings.sortOrder)
            return "asc";

        return form.settings.sortOrder;
    }

    responsesFor(qId: string) {
        let result: any[] = [];
        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(qId);

        this.responses
            .filter(o => FhirService.FhirVersion > 3 ? (String(o.questionnaire).toUpperCase().indexOf(questionnaire.name.toUpperCase()) > -1) : (o.questionnaire.reference.endsWith(qId)))
            .forEach(item => {
                let authored = moment(item.authored).format(RuntimeInfo.DateTimeFormat);
                let updated = item.meta && item.meta.lastUpdated ? moment(item.meta.lastUpdated).format(RuntimeInfo.DateTimeFormat) : authored;
                item.text = {
                    div: `${authored} (${this.i18n.tr(item.status)}, aktualisiert: ${updated})`,
                    status: 'generated'
                };

                if (['completed', 'amended', 'in-progress'].indexOf(item.status) === -1) {
                    item.text.div = `${authored} (${this.i18n.tr("response_status")} "${this.i18n.tr(item.status)}" ${this.i18n.tr("on")} ${updated})`
                }

                result.push(item);
            });

        result = result.sort((a, b) => {
            return new Date(a.authored).valueOf() - new Date(b.authored).valueOf();
        });

        return result;
    }

    async showStornoDetails(response: any) {
        if (!response || !response.author || !response.author.reference) return;
        let author = <any>await this.fhirService.get(response.author.reference);

        let name = "";
        if (author && author.name) {
            author.name.forEach(n => {
                name += n.family + ', ' + n.given?.join(' ');
            });
        }

        if (!name || name === ", ") name = this.i18n.tr("unknown");

        let authored = moment(response.authored).format(RuntimeInfo.DateTimeFormat);
        const status = this.i18n.tr(response.status);
        let updated = response.meta && response.meta.lastUpdated ? moment(response.meta.lastUpdated).format(RuntimeInfo.DateTimeFormat) : authored;
        let by = this.i18n.tr('questionnaire_changed_by').replace('.. ', '');
        let msg = `${this.i18n.tr('questionnaire_changed')}: <b>${updated}</b><br />${by}: <b>${name}</b><br />${this.i18n.tr("status")}: <b>${status}</b>`;
        this.dialogMessages.prompt(msg, this.i18n.tr('information'), false);
    }

    async printReport(response, questionnaireSetting) {
        this.selectedReponseId = response.id;
        PatientService.AddQuestionnaireResponse(this.patient, response, true);

        let checkResult = await ReportService.RequirementsFulfilled(this.i18n, questionnaireSetting.setting, this.patient);

        if (!checkResult.fulfilled) {
            let html = '';
            checkResult.message.forEach(m => html += `<div>${m.text}</div>`);
            this.dialogMessages.prompt(html, this.i18n.tr('information'), true);
        } else {
            let rn = questionnaireSetting.reportName;
            if (typeof questionnaireSetting.reportName === "object" && questionnaireSetting.reportName.name)
                rn = questionnaireSetting.reportName.name;

            ReportService.Preview(response.id, rn);
        }
    }

    openPdfWindow(responseId: string, report: string) {
        const pdfHref = `${ReportService.ReportServer}/api/Report/Generate/${UserService.Practitioner.id}/${this.patient.id}/${this.patient.encounterId}/${responseId}/${report}`;
        window.open(pdfHref, `report_${responseId}`, 'popup=yes,noreferrer=yes,noopener=yes,location=no,resizable=yes,status=no,toolbar=no');
    }

    async printFixedReport(report: string, asPdf: boolean) {
        this.showingReportSelection = false; // to be sure it's hidden again
        if (!report)
            return;

        report = report.toUpperCase();
        if (!report.endsWith('.FRX'))
            report += '.FRX';

        let latestResponse = this.questionnaireService.getLatestResponseOfType(this.patient, this.qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (!latestResponse)
            latestResponse = this.questionnaireService.getLatestResponseOfType(this.patient, this.qList.QAssessmentId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (!latestResponse)
            latestResponse = this.patient.questionnaireResponses.find(o => ['amended', 'completed'].indexOf(o.status) > -1);
        if (!latestResponse)
            latestResponse = this.patient.questionnaireResponses.find(o => o.status === 'in-progress');

        if (!latestResponse) {
            console.warn('No QuestionnaireResponse found for creating Report. Creating fallback CareITEncounterAdditionalInfo..');
            // when no response has been found, no addition info at least is present, so we need to create one manually
            const additionalInfoQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect("CareITEncounterAdditionalInfo");
            if (additionalInfoQuestionnaire) {
                const response = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, additionalInfoQuestionnaire, 'in-progress');
                if (response) {
                    response.item = [];
                    try {
                        await Fhir.Rest.Update(response);
                        latestResponse = response;
                        console.info('Created fallback response');
                    }
                    catch (e) {
                        console.warn('Could not store Response on Fhir-Server', e);
                    }
                } else {
                    console.warn("Could not successfully create a new response");
                }
            } else {
                console.warn("CareITEncounterAdditionalInfo Questionnaire not found!");
            }
        }

        if (!latestResponse) {
            console.warn('No QuestionnaireResponse found for creating Report');
            return;
        }

        if (asPdf) {
            this.openPdfWindow(latestResponse.id, report);
        } else {
            ReportService.Preview(latestResponse?.id || '-', report);
        }
    }

    async printControlling(asPdf: boolean) {
        let report = "controlling.frx";
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        let settings = ConfigService.GetFormSettings("carefiles");
        if (settings && settings.report && settings.report.name)
            report = settings.report.name;

        let latestResponse = this.questionnaireService.getLatestResponseOfType(this.patient, this.qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (!latestResponse)
            latestResponse = this.questionnaireService.getLatestResponseOfType(this.patient, this.qList.QAssessmentId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (!latestResponse)
            latestResponse = this.patient.questionnaireResponses.find(o => ['amended', 'completed'].indexOf(o.status) > -1);

        if (asPdf)
            this.openPdfWindow(latestResponse.id, report);
        else
            ReportService.Preview(latestResponse?.id || '-', report);

        /* if (latestAnamnesis) {
            ReportService.Preview(latestAnamnesis.id, report);
        } else {
            this.dialogMessages.prompt(this.i18n.tr("controlling_no_anamnesis"), this.i18n.tr("print_requirements_not_met"), false);
        } */
    }

    async createQuestionnaireList() {
        try {
            if (!this.patient) return;
            RuntimeInfo.IsLoading = true;

            // ensure correct default settings
            const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            let defaultSettings = cfg.forms.find(o => o.route === "FORMS_DEFAULT");
            if (!defaultSettings) {
                defaultSettings = {
                    "route": "FORMS_DEFAULT",
                    "info": "Default-Settings for forms that do not have a distinct configured route",
                    "enabled": true,
                    "settings": {
                        "careFiles": {
                            "order": "asc",
                            "field": "authored",
                            "list": true,
                            "index": 999
                        }
                    }
                }
            }

            let careFilesSettings: IFormSetting[] = []; // here we store the items to list on the page

            // get all active Questionnaires:
            let questionnaires = await QuestionnaireService.Fetch();
            questionnaires = questionnaires.filter(o => o.status === "active");

            // get the settings for each questionnaire
            questionnaires.forEach(q => {
                let setting = cfg.forms.find(o => o && o.questionnaireName && o.questionnaireName.toUpperCase() === (q.name || q.title).toUpperCase());

                try {
                    if (!setting) {
                        setting = NitTools.Clone(defaultSettings);
                        setting.report = {name: q.name || q.title}
                        setting.title = this.i18n.tr((q.title || q.name).toLowerCase());
                        setting.questionnaireName = q.name || q.title;
                        setting.route = 'questionnaire/{responseId}/' + q.name;
                    }

                    if (!setting.settings) setting.settings = {};

                    if (!setting.settings.careFiles) {
                        setting.settings.careFiles = {
                            list: false,
                            field: "authored",
                            index: 100000,
                            order: "asc"
                        }
                    }

                    if (typeof setting.settings.careFiles.index === "undefined") setting.settings.careFiles.index = 100000;
                } catch (err) {
                    console.warn(err);
                }

                try {
                    if (setting.enabled === true && setting.settings.careFiles.list === true) {
                        if (setting.questionnaireName.toUpperCase().indexOf('CAREITWOUND_') === -1 &&
                            setting.questionnaireName.toLowerCase() !== 'careitencounteradditionalinfo'
                        ) // don't add the fancy wound questionnaires and not the additional info
                            careFilesSettings.push(setting);
                    }
                } catch (err) {
                    console.warn(err);
                }
            })

            careFilesSettings = careFilesSettings.sort((a, b) => {
                return a.title.localeCompare(b.title)
            })

            // sort the resulting list
            careFilesSettings = careFilesSettings.sort((a, b) => {
                if (typeof a.settings.careFiles.index === "number" && typeof b.settings.careFiles.index === "number")
                    return a.settings.careFiles.index - b.settings.careFiles.index;
                else {
                    if (typeof a.settings.careFiles.index === "undefined") return 1;
                    else return -1;
                }
            })

            let fixUpdateBundleItems = [];
            for (const careFilesSetting of careFilesSettings) {
                try {
                    fixUpdateBundleItems.push(...await this.addItemToReports(careFilesSetting));
                } catch (e) {
                    console.warn(e);
                }
            }

            try {
                await this.loadWounds();
            } catch (error) {
                console.warn(error);
            }

            fixUpdateBundleItems = fixUpdateBundleItems.filter(o => o);

            if (fixUpdateBundleItems.length > 0) {
                if (ConfigService.Debug) {
                    console.debug('Updating responses with missing author.display', fixUpdateBundleItems);
                }

                await this.fhirService.bundle(fixUpdateBundleItems, HTTPVerb.put, BundleType.batch, false);
            }
        } catch (e) {
            console.warn(e);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    /***
     * Adjusts the author.display value according to the value configured in settings.authorDisplay.<br />
     * could be one of [none, initials, full, full-icon, initials-icon].
     * @param response the any to adjuste the author-display for
     */
    async adjustAuthorName(response: any) {
        if (!response || !response.author || !response.author.reference) return;

        const practitioner = await UserService.GetPractitioner(response.author.reference, this.fhirService);

        if (practitioner && this.config && this.config.settings.authorDisplay) {
            const hn = UserService.GetNameObject(practitioner);
            const div = `<span xmlns="http://www.w3.org/1999/xhtml">${hn ? hn.text : '-'}</span>`;
            if (!practitioner.text || !practitioner.text.div) {
                practitioner.text = {status: 'generated', div: div};
            }

            response.author.display = practitioner.text.div.replace(/<[^>]*>/g, '');

            // noinspection FallThroughInSwitchStatementJS
            switch (this.config.settings.authorDisplay) {
                case 'none':
                    response.author.display = '';
                    break;
                case 'initials-icon':
                    this.showNameIcon = true;
                case 'initials':
                    response.author.display = hn.given && hn.given[0] && hn.given[0].length >= 1 ? hn.given[0][0] + '.' : '';
                    if (hn.family && hn.family.length > 0) {
                        response.author.display += hn.family[0] + '.';
                    }

                    break;
                case 'full-icon':
                    this.showNameIcon = true;
                case 'full':
                    // already filled per default with full name
                    break;
                default:
                    console.warn('unsupported value for carefiles.settings.authorDisplay: "' + this.config.settings.authorDisplay + '".'
                        + '\nonly none|initials|full|full-icon|initials-icon allowed');
                    break;
            }
        }

    }

    async loadWounds() {
        this.woundItems = []; // always initialize the woundItems!

        // load the global settings for wounds
        this.woundSettings = ConfigService.FormSettings.find(o => o && o.route && o.route.toUpperCase() === "WOUNDS");

        let doDisplay = false;
        // 1st check if the wounds are enabled at all
        if (typeof this.woundSettings?.enabled === "boolean") {
            doDisplay = this.woundSettings.enabled;
            // when wounds are enabled take a look at the care-files section to see if listing is explicitly switched off
            if (doDisplay && typeof this.woundSettings?.settings?.careFiles?.list === "boolean") {
                doDisplay = this.woundSettings.settings.careFiles.list;
            }
        }

        this.displayWounds = doDisplay;
        if (!doDisplay) // when the wounds are not displayed we do not need to process them further
            return;

        let sortField : string = this.woundSettings?.settings?.careFiles?.field || 'authored';
        let sortOrder : string = (this.woundSettings?.settings?.careFiles?.order || 'asc').toUpperCase();
        if (ConfigService.Debug) {
            console.debug(`Sorting wound responses with field "${sortField}" from Config`);
        }

        //TODO: check what is running inside this function, because it is much too much!
        await GrafixxItem.Init();

        GrafixxItem.Default.forEach((grafixxItem: IGrafixxItem) => {
            let group = new WoundGroup(grafixxItem);
            group.children = [];
            this.woundItems.push(group);
        });

        // check or create wound groups for this wound
        const observations: any[] = await this.loadObservations();
        observations.forEach((observation: any) => {
            if (observation.category && observation.category[0] && observation.category[0].text) {
                if (this.woundItems) {
                    let grp = this.woundItems.find(o => o.item?.type === observation.category[0].text);
                    if (grp) {
                        let wgi = new WoundGroupItem(observation);
                        if (wgi.valid) {
                            grp.children.push(wgi);
                        }
                    }
                }
            }
        });

        //sort the items by id
        this.woundItems.forEach(grp => {
            // sort by identifier if possible (which is the number, displayed in the icon)
            grp.children?.sort((a: WoundGroupItem, b: WoundGroupItem) => {
                let bId = 10000;
                let aId = 9999;
                if (b && b.item && b.item.identifier && b.item.identifier[0] && b.item.identifier[0].value) bId = parseInt(b.item.identifier[0].value);
                if (a && a.item && a.item.identifier && a.item.identifier[0] && a.item.identifier[0].value) aId = parseInt(a.item.identifier[0].value);

                return aId - bId;
            })

            grp.item.imageName = `images/bodies/icons/${grp.item.imageName}.svg`;
            grp.uid = NitTools.UidName();
        });

        this.loadWoundResponses()
            .then(async () => {
                const kvp: { woundId: string, items: string[] }[] = [];
                if (!this.woundResponseListEntries) return;

                // convert the list-entries to a Dictionary-Style array
                this.woundResponseListEntries.filter(o => o.flag && o.flag.coding && o.flag.coding)
                    .forEach((lEntry: any) => {
                        const woundIdCoding: any = lEntry.flag.coding.find(o => o.system.toUpperCase().endsWith('WOUND-ID'));
                        if (woundIdCoding) {
                            let existing = kvp.find(o => o.woundId === woundIdCoding.code);
                            if (!existing) {
                                existing = {woundId: woundIdCoding.code, items: []}
                                kvp.push(existing);
                            }

                            if (!existing.items) existing.items = [];
                            let ref = lEntry.item.reference;
                            if (ref.indexOf('/') > -1) ref = ref.split('/')[1];
                            existing.items.push(ref);
                        }
                    });

                for (const wGroup of this.woundItems) {
                    wGroup['count'] = 0;
                    for (const wChild of wGroup.children) {
                        wChild['count'] = 0;
                        if (wChild.item) {
                            const childWoundId = wChild.item.id;
                            const matchingListEntry = kvp.find(o => o.woundId === childWoundId);
                            if (matchingListEntry) {
                                if (!wChild.item.responses) {
                                    wChild.item.responses = [];
                                }

                                for (const match of matchingListEntry.items) {
                                    try {
                                        let response = QuestionnaireService.GetResponseByIdDirect(this.patient, match);
                                        if (!response) {
                                            this.loadingWounds = true;
                                            this.toLoad.push(match)
                                            response = await QuestionnaireService.GetResponseById(this.patient, match);
                                        }

                                        if (response) {
                                            PatientService.AddQuestionnaireResponse(this.patient, response, true);
                                            wChild.item.responses.push(response);
                                            await this.adjustAuthorName(response);
                                        }

                                        const idx = this.toLoad.indexOf(match);
                                        if (idx > -1) this.toLoad.splice(idx, 1);
                                        this.loadingWounds = this.toLoad.length > 0;
                                    } catch (error) {
                                        console.warn(error)
                                    }
                                }
                            }

                            wChild['uid'] = NitTools.UidName();

                            if (wChild.item.responses) {
                                wChild['count'] += wChild.item.responses.length;
                                this.totalWoundsCount += wChild['count'];
                            }
                        }

                        wGroup['count'] += wChild['count'];
                    }
                }
            })

        const isDateFormat = function (stringVal) {
            if (!stringVal)
                return false;

            return /([123456789]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(stringVal);
        }

        // get the responses for each wound
        for (const wGroup of this.woundItems) {
            wGroup.hasResponses = false;
            if (wGroup.item && wGroup.item.type) {
                const questionnaireName = `CareITWound_${wGroup.item.type}`
                const questionnaire = QuestionnaireService.Questionnaires.find(o => o.status === 'active' && o.name.toUpperCase() === questionnaireName.toUpperCase());
                if (questionnaire) {
                    for (const woundLocation of wGroup.children) {
                        if (woundLocation.item && woundLocation.item.id) {
                            //   woundLocation.item.responses = this.responsesFor(questionnaire.id);
                            const woundId = woundLocation.item.id;
                            let woundResponses = this.responses.filter(o => o.extension);
                            woundResponses.sort((a, b) => {
                                let compDateA = new Date('1999-12-12');
                                let compDateB = new Date('1999-12-12');

                                if (isDateFormat(a[sortField]))
                                    compDateA = new Date(a[sortField]);

                                if (isDateFormat(b[sortField]))
                                    compDateB = new Date(b[sortField]);

                                if (sortOrder === 'DESC')
                                    return compDateA.valueOf() - compDateB.valueOf();
                                else
                                    return compDateB.valueOf() - compDateA.valueOf();
                            })

                            for (const response of woundResponses) {
                                const extWoundId = response.extension.find(o => o.url.toUpperCase().endsWith('/StructureDefinition/wound-id'.toUpperCase()));
                                if (extWoundId && extWoundId.valueId && extWoundId.valueId === woundId) {
                                    if (!woundLocation.item.responses) woundLocation.item.responses = [];
                                    await this.adjustAuthorName(response);
                                    woundLocation.item.responses.push(response);
                                    wGroup.hasResponses = true;
                                }
                            }
                            // console.warn(woundLocation.item.id, woundLocation.item);
                        }
                    }
                }
            }
        }

        this.loadingWounds = false;
    }

    loadObservations(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.patient) {
                reject("No Patient loaded");
                return;
            }

            this.fhirService.fetch(`${fhirEnums.ResourceType.observation}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.patient.encounter.id}`)
                .then(async (result: any[]) => {
                    let observations = result.filter(o => (o.category && o.category[0] && o.category[0].text)
                        && (o.status !== fhirEnums.ObservationStatus.cancelled && o.status !== fhirEnums.ObservationStatus.enteredInError)
                    );

                    observations = observations.filter(o => o.status !== 'cancelled' && o.status !== 'entered-in-error');
                    // select either the last selected wound, or the 1st in list

                    resolve(observations);
                })
                .catch(error => {
                    console.warn(error);
                    reject("Error when loading Observations: " + error);
                })
        })
    }

    loadWoundResponses(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let woundGroupLists = [];
                this.woundResponseListEntries = [];
                let result: any[] = await this.fhirService.fetch(`List?encounter=${this.patient.encounterId}&code=${RuntimeInfo.SystemHeader}/body-map-group|`);
                result.filter(o => o.entry).forEach((list) => {
                    // only get entries that point to a wound-id and contain an item
                    const entries = list.entry.filter(o => o
                        && o.item && o.item.reference
                        && o.flag && o.flag.coding
                        && typeof o.flag.coding.find(f => f.system.endsWith('wound-id')) !== 'undefined'
                    );
                    this.woundResponseListEntries.push(...entries);

                    let typeItem = list.code.coding.find(o => o.system === RuntimeInfo.SystemHeader + "/body-map-group");
                    let type = "other";
                    if (typeItem && typeItem.code) type = typeItem.code;
                    woundGroupLists.push({
                        listId: list.id,
                        type: type,
                        title: list.title,
                        observations: (<any[]>list.entry).map(e => {
                            return e.item.reference.split('/')[1]
                        })
                    })
                });

                resolve(woundGroupLists);
            } catch (e) {
                console.warn(e);
                reject(e);
            }
        })
    }

    printWoundReport(response: any, type: string) {
        ReportService.Preview(response.id, `W_${type}`);
    }

    showAuthor(response: any) {
        if (!response || !response.author || !response.author.display) return;
        this.dialogMessages.prompt(`${this.i18n.tr("response_created")} <span>${response.text.div}</span><br />
                                            ${this.i18n.tr("by")} ${response.author.display}`
        );
    }

    async addItemToReports(config: IFormSetting): Promise<any[]> {
        let questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(config.questionnaireName);
        let responses = this.responsesFor(questionnaire.id);
        let _items: any[] = [];
        let route = config.route;
        let updateResources = [];

        // set the text.div to the specified field
        for (const response of responses) {
            PatientService.AddQuestionnaireResponse(this.patient, response, true);
            let responseTemp: any = NitTools.Clone(response);

            let f = fhirpath.evaluate(responseTemp, config.settings.careFiles.field, null, fhirpath_stu3_model);
            if (!f) f = responseTemp.authored;

            try {
                f = new Date(f)
            } catch (err) {
                console.warn(err);
                f = Date.parse(f);
            }

            f = moment(f);

            responseTemp.text = {
                div: `${f.format(RuntimeInfo.DateTimeFormat)}`,
                status: response.text.status
            };

            // create temp field for sorting index
            responseTemp["_sortField"] = moment(f).valueOf(); // .toJSON(); // (f ? f.toDate() : new Date()).valueOf();

            // because we may need to fix either the response or adjust the Practitioner-Display in carefiles, always load the practitioner
            // as UserService.GetPractitioner uses caching, this is a onetime load
            let practitioner: any;
            if (responseTemp.author && responseTemp.author.reference)
                practitioner = await UserService.GetPractitioner(responseTemp.author.reference, this.fhirService);

            // fix: insert the response.author.display if not existent
            if (responseTemp.author && !responseTemp.author.display && practitioner) {
                if (practitioner && practitioner.text && practitioner.text.div) {
                    responseTemp.author.display = practitioner.text.div.replace(/<[^>]*>/g, '');
                    // add a *clone* to the update items array. Use clone, because we may change the author.display later, according to the setting
                    updateResources.push(NitTools.Clone(responseTemp));
                }
            }

            // change the author.display according to the settings
            await this.adjustAuthorName(responseTemp);

            let edit = route;
            if (config.route.toUpperCase().indexOf('BARTHELINDEX') > -1 || config.route.toUpperCase() === "FIM") {
                // do not provide edit links for BI/biEx/FIM
                edit = '';
            } else {
                if (edit.indexOf('{responseId}') > -1) {
                    edit = `${edit.replace('{responseId}', response.id)}`;
                } else {
                    edit = `${NitTools.ExcludeTrailingSlash(route)}/${response.id}`
                }
            }

            responseTemp['_edit'] = edit;

            _items.push(responseTemp);
        }

        const asc = config.settings.careFiles.order === "asc";
        _items = _items.sort((a, b) => {
            return asc ? a["_sortField"] - b["_sortField"] : b["_sortField"] - a["_sortField"];
        })

        // remove temp field
        _items.forEach(i => delete i["_sortField"]);

        const pr = {
            questionnaire: questionnaire.id,
            reportName: config.report,
            name: this.i18n.tr(questionnaire.title || questionnaire.name),
            items: _items,
            setting: config,
            counts: {
                completed: _items.filter(o => o.status === "completed" || o.status === "amended").length,
                completedTitle: this.i18n.tr('completed'),
                inProgress: _items.filter(o => o.status === "in-progress").length,
                inProgressTitle: this.i18n.tr('in-progress'),
                canceled: _items.filter(o => o.status === "entered-in-error" || o.status === "stopped").length,
                canceledTitle: this.i18n.tr('entered-in-error') + '/' + this.i18n.tr('stopped')
            },
            hasCounts: _items.length > 0
        };

        this.printableReports.push(pr);

        return Promise.resolve(updateResources);
    }

    nav(url: string, response: any) {
        const sa = url.split('/');
        const editRoute = sa[0];
        const definedRoute = this.router.routes.find(o => o.route.indexOf(editRoute) === 0);
        if (!url || !definedRoute) {
            const questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
            if (questionnaire) {
                url = `questionnaire/${questionnaire.id}/${questionnaire.name}/${response.id}`;
            }
        }

        if (url) this.router.navigate(url);
    }

    async loadPatient(): Promise<PatientItem> {
        try {
            RuntimeInfo.IsLoading = true;
            this.patient = await this.patientService.fetch(this.encounterId);

            const c = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            this.showPrint = !!ConfigService.cfg.reportServer;

            // allowEditProgressReportItems
            const shiftConfig = c?.forms?.find(o=>o.route === 'shift-report');
            if (shiftConfig?.settings) {
                if (typeof shiftConfig.settings.allowEditEntries === 'boolean')
                    this.allowEditProgressReportItems = shiftConfig.settings.allowEditEntries;
            }

            await this.createQuestionnaireList();
            this.loadProgressReport();

            let settings = ConfigService.GetFormSettings(this.settingName);
            if (settings && settings.report && settings.report.other && settings.report.other.additional) {
                const additionals = settings.report.other.additional;
                for (const name of Object.keys(additionals)) {
                    if (name) {
                        const setting = additionals[name];
                        if (setting && setting.enabled == true && setting.report) {
                            setting.title = this.i18n.tr(setting.title);
                            if (name === "controlling") {
                                this.controllingItem = setting;
                            }

                            this.additionalReports.push(setting);
                        }
                    }
                }
            }

            return Promise.resolve(this.patient);
        } catch (e) {
            console.warn(e.message);
            return Promise.reject("Error when loading patient");
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    createMdkAccordions() {
        this.mdkAccordionItems = [
            {
                id: "",
                title: "Pflegeanamnese",
                numItems: 0,
                questionnaireName: "CareITAnamnesis",
                items: [],
                type: PAItemType.questionnaireResponse,
                report: ConfigService.GetFormSettings(ConfigService.FormNames.Anamnesis).report.name
            },
            {
                id: "",
                title: "Pflegeberichte",
                numItems: 0,
                items: [],
                type: PAItemType.observation,
                query: `code=${LOINC.SYSTEM}|${LOINC.CODES.REPORT.code}`
            },
            {id: "", title: "PKMS-Dokumentation", numItems: 0, items: [], type: PAItemType.carePlan},
            {
                id: "", title: "Dekubitus-Dokumentation", numItems: 0, items: [], type: PAItemType.observation,
                query: `code:text=body-map`
            },
            {
                id: "",
                title: "Barthel-Index",
                numItems: 0,
                questionnaireName: ConfigService.GetFormSettings(ConfigService.FormNames.BarthelIndexEx).questionnaireName,
                items: [],
                type: PAItemType.questionnaireResponse,
                report: ConfigService.GetFormSettings(ConfigService.FormNames.BarthelIndexEx).report.name
            },
            {
                id: "",
                title: "Isolations-Dokumentation",
                numItems: 0,
                questionnaireName: "CareITAdmin_ISO",
                items: [],
                type: PAItemType.questionnaireResponse,
                report: ConfigService.GetFormSettings(ConfigService.FormNames.Isolation).report.name
            },
            {id: "", title: "Analyse/Mangelernährung/NRS", numItems: 0, items: [], type: PAItemType.unknown}
        ];
    }

    printAll() {
        if (!this.mdkData || !this.mdkData.reports) return;

        let url = ReportService.ReportServer;
        if (!url.endsWith('/')) {
            url += '/';
        }

        url += 'Print/MultiPrint';

        /* use $.post here, as the httpclient fails with OPTION and CORS */
        this.mdkData.encounterId = this.patient.encounterId;
        $.post(url, this.mdkData, (data: string) => {
            let js = JSON.parse(data);
            if (js.images) {
                let html = "";
                js.images.forEach(img => {
                    html += `<div class="image-parent"><img src="data:image/jpeg;base64,${img}" /></div>\n`;
                });

                RuntimeInfo.PrintFrame.innerHTML = html;
                // let printUrl = `${ReportService.ReportServer}Print/Pdf/${this.patient.encounterId}/${js.pdfUid}`;
                RuntimeInfo.PrintFrame.setAttribute("data-clear-url", js.clearUrl);
                RuntimeInfo.PrintFrame.setAttribute("data-pdf-url", js.PdfUrl);
            } else {
                RuntimeInfo.PrintFrame.innerHTML = data;
            }

            RuntimeInfo.PrintDialog.style.display = "block";
        }).catch((e: jqXHR) => {
            this.dialogMessages.showHttpError(e, translations.translate("print_error"));
        });
    }

    loadProgressReport() {
        this.fhirService.fetch(`${fhirEnums.ResourceType.observation}?subject=${this.patient.id}&encounter=${this.patient.encounterId}&code=${LOINC.SYSTEM}|${LOINC.CODES.REPORT.code}`)
            .then((result: any[]) => {
                this.progressReports = result.map((res) => this.parseReport(res)).sort((a, b) => {
                    if (moment(a.datetime).isAfter(moment(b.datetime))) return -1;
                    if (moment(b.datetime).isAfter(moment(a.datetime))) return 1;

                    return 0;
                });
            });
    }

    parseReport(data: any) {
        return {
            id: data.id,
            datetime: moment(data.effectiveDateTime).toDate(),
            shift: this.findComponent('shift', data),
            markSupplement: this.findComponent('mark-supplement', data, true),
            nursingTransfer: this.findComponent('nursing-transfer', data, true),
            additionalInfo: this.findComponent('additional-info', data, true),
            status: data.status,
            reportText: data.valueString,
            user: data.performer && data.performer[0] ? data.performer[0].display : '',
            lastUpdated: data.meta.lastUpdated,
            comment: data.comment || ''
        }
    }

    findComponent(name, data, isBoolean = false) {
        const val = data.component ? data.component.find((cmp) => cmp.code.text === name) : null

        if (val) {
            return isBoolean ? val.valueString === 'true' : val.valueString
        } else {
            return isBoolean ? false : null
        }
    }

    editShiftReport(idx) {
        this.dialogService.open({
            viewModel: ModalShiftReport,
            model: {
                mode: 'edit',
                patient: this.patient,
                report: this.progressReports[idx]
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.progressReports.splice(idx, 1, this.parseReport(result.output));
            }
        });
    }

    deleteShiftReport(idx) {
        let _this = this;

        function deleteReport() {
            _this.fhirService.delete({
                id: _this.progressReports[idx].id,
                resourceType: fhirEnums.ResourceType.observation
            }).then(() => {
                _this.progressReports.splice(idx, 1);
            });
        }

        this.dialogService.open({
            viewModel: Prompt, model: {message: translations.translate("confirm")}
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                const id = this.progressReports[idx].id;

                this.fhirService.fetch(`Procedure?part-of=${id}`).then((result: any) => {
                    if (result.length > 0) {
                        const procedure = result[0];

                        delete procedure.partOf;
                        delete procedure.followUp;

                        this.fhirService.update(procedure).then(deleteReport.bind(this));
                    } else {
                        deleteReport.call(this);
                    }
                });
            }
        });
    }

    /** this only works on on iE-Browsers, but was too much work just to delete it :) */
    getSpiderJPeg() {
        let canvas: HTMLCanvasElement = document.createElement("canvas");
        let spider: any = document.querySelector(".spi svg");
        let img = new Image(); // document.createElement("img");
        let svgSrc = spider.parentNode.innerHTML;

        canvas.width = spider.clientWidth;
        canvas.height = spider.clientHeight;
        canvas.style.width = canvas.width + "px";
        canvas.style.height = canvas.height + "px";

        let ctx = canvas.getContext("2d");

        if (spider) {
            img.onload = () => {
                ctx.drawImage(img, 1, 1, ctx.canvas.width, ctx.canvas.height);
                /* let src = canvas.toDataURL('image/png');
                console.debug("Src Len:", src.length); */
            };

            let svgBlob: Blob = new Blob([svgSrc], {type: 'image/svg+xml'});
            let reader = new FileReader();
            reader.readAsDataURL(svgBlob);
            reader.onloadend = function () {
                img.src = <string>reader.result;
            };
        }
    }

    openHistory(idx) {
        this.dialogService.open({
            viewModel: ModalShiftReportHistory,
            model: {
                report: this.progressReports[idx]
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
            }
        });
    }
}

export enum PAViewMode {
    akte = "akte",
    mdk = "mdk"
}

export enum PAItemType {
    observation = "observation",
    questionnaireResponse = "questionnaireResponse",
    analyze = "analyze",
    unknown = "unknown",
    carePlan = "carePlan"
}

export class PAQuestionnaireItem {
    text: string;
    id: string;

    constructor(qu: any) {
        this.id = qu.id;
        this.text = moment(qu.authored).format(RuntimeInfo.DateTimeFormat);
    }
}

export class MultiPrintResponse {
    questionnaireResponseId: string = "";
    svgImage: string = "";
}

export interface IMDKReport {
    name: string;
    responses: MultiPrintResponse[];
}

export class MDKData {
    encounterId: string;
    reports: IMDKReport[];
    pdf?: any;
    employeeGivenName: string = "";
    employeeLastName: string = "";
    employeeId: string = "";

    constructor(data?: any) {
        this.reports = [];
        this.pdf = undefined;
        if (RuntimeInfo.Employee) {
            this.employeeGivenName = RuntimeInfo.Employee.givenName;
            this.employeeLastName = RuntimeInfo.Employee.familyName;
            this.employeeId = RuntimeInfo.Employee.userId;
        }

        if (data) {
            Object.apply(this, data);
        }
    }
}
