import {autoinject, bindable, TaskQueue} from "aurelia-framework";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {App} from "../../app";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {IEncounterViewDefinition, IEncounterViewDefinitionArrow} from "../encounter-view-definition";
import {ConfigService} from "../../resources/services/ConfigService";
import {UserService} from "../../resources/services/UserService";
import {QuestionnaireService} from "../../resources/services/QuestionnaireService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {Tools} from "../../resources/classes/FhirModules/Tools";
import {Questionnaire} from "../../resources/classes/FhirModules/Fhir";
import {NitTools} from "../../resources/classes/NursitTools";
import {FhirService} from "../../resources/services/FhirService";
import SystemHeaders from "../../resources/classes/SystemHeaders";
import HTTPVerb = fhirEnums.HTTPVerb;

@autoinject
export class MultiForm {
    @bindable patient: PatientItem;
    @bindable encounterId: string;
    @bindable definitionId: string;
    @bindable selectedListId : string;
    viewDefinition: IEncounterViewDefinitionArrow;
    questionnaireNames: string[];
    data: IMultiFormData[];
    questionnaires: ExtendedQuestionnaire[] = [];
    multiformQuestionnaires: IMultiformQuestionnaire[];
    positionData: any[];
    isScrolling: boolean = false;
    maxTitleLength = 24;
    lastChangedFormId: string;
    readyForChanges : boolean = false;

    constructor(protected app: App, protected i18n: I18N, protected router: Router, protected taskQueue: TaskQueue, protected fhirService : FhirService) {
    }

    async activate(params) {
        this.encounterId = params.id;
        this.definitionId = params.definitionId;

        RuntimeInfo.IsLoading = false;
        if (ConfigService.Debug) {
            window["multiform"] = this;
        }
    }

    stopIt(e: PointerEvent, questionnaire: ExtendedQuestionnaire) {
        e.stopPropagation();

        if (typeof questionnaire.__isSelected === 'undefined')
            questionnaire.__isSelected = false;

        questionnaire.__isSelected = !questionnaire.__isSelected;

        if (questionnaire.__isSelected) {
            // selected and no item exists, then create a new one
            this.addQuestionnaire(questionnaire, undefined);
        } else {
            this.removeQuestionnaire(questionnaire);
        }

        console.warn(this.multiformQuestionnaires, this.data);
    }

    removeQuestionnaire(questionnaire: ExtendedQuestionnaire) {
        let mf = this.multiformQuestionnaires.find(o => o.id === questionnaire.id);
        let dataItem = this.data.find(o => o.questionnaire.id === questionnaire.id);
        questionnaire.__isSelected = false;

        // not selected, but item exists in list, then remove it
        if (mf) {
            const idx = this.multiformQuestionnaires.indexOf(mf);
            this.multiformQuestionnaires.splice(idx, 1);
        }

        // remove from data
        if (dataItem) {
            this.data.splice(this.data.indexOf(dataItem), 1);
        }
    }

    formChanged(questionnaire) {
        if (!this.readyForChanges) return;
        const item = questionnaire.changedField;
        if (!item) return;
        let linkId = String(item?.linkId);

        if  (linkId == this.lastChangedFormId) return;
        this.lastChangedFormId = linkId;

        let d = this.data.find(o=>o.selectedResponse?.id === questionnaire.response?.id);
        if (d) d.hasChanges = true;

        const formItem = Questionnaire.GetQuestionnaireItemByLinkId(d?.questionnaire, linkId);
        console.log(formItem.text + ' . ' + linkId + ' <-> ' + this.lastChangedFormId);

        let mf = this.multiformQuestionnaires.find(o=>o.id === questionnaire.questionnaire?.id);
        if (mf) mf.hasChanges = true;
    }

    getTitle(txt : string) : string {
        if (!txt) return '';
        if (txt.length > this.maxTitleLength) {
            txt = txt.substring(0, this.maxTitleLength -2) + '..';
        }

        return txt;
    }

    addQuestionnaire(questionnaire: ExtendedQuestionnaire, response : any) {
        if (!this.multiformQuestionnaires) this.multiformQuestionnaires = [];
        if (!this.data) this.data = [];

        let mf = this.multiformQuestionnaires.find(o => o.id === questionnaire.id);
        let dataItem = this.data.find(o => o.questionnaire.id === questionnaire.id);
        questionnaire.__isSelected = true;
        // selected and no item exists, then create a new one
        if (!mf) {
            mf = {id: questionnaire.id, title: this.getTitle(questionnaire.title), groups: [], isNewResponse: false};
            const groups = questionnaire.item?.filter(o => o.type === "group") || [];
            if (groups.length >= 2) {
                for (const group of groups) {
                    if (group.extension?.find(o => o.url?.endsWith('/questionnaire-hidden') && o.valueBoolean === true)) continue;

                    mf.groups.push({linkId: group.linkId, text: this.getTitle(group.text), isVisible: true, hint: group.text});
                }
            }

            mf.hasGroups = groups.length > 0;

            // add to the questionnaires
            this.multiformQuestionnaires.push(mf);

            this.multiformQuestionnaires.sort((a,b) => {
                return String(a.title || '_no_name_').localeCompare(String(b.title || '_no_name_'));
            })
        }

        // add to data
        if (!dataItem) {
            let isNew = false;
            let questionnaireResponse = response; // QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
            if (!questionnaireResponse) {
                questionnaireResponse = Tools.SubstituteDefaultQRSkeleton(this.patient, questionnaire.id, "in-progress");
                isNew = true;
            }

            Questionnaire.EnsureStructuredResponse(questionnaire, questionnaireResponse);
            dataItem = {
                isNew: isNew,
                questionnaire: questionnaire,
                responses: [],
                selectedResponse: questionnaireResponse
            };

            this.data.push(dataItem);
            this.data.sort((a ,b) => {
                return String(a.questionnaire?.title || '_no_name_').localeCompare(String(b.questionnaire?.title || '_no_name_'));
            });

            mf.isNewResponse = dataItem.isNew;
        }
    }

    async loadData() {
        if (!this.encounterId || !this.definitionId) return;

        this.currentList = undefined;
        this.questionnaires = undefined;
        this.multiformQuestionnaires = undefined;
        this.data = undefined;
        console.warn("loadData()");

        const scroller = document.querySelector('.mf-questionnaires-parent');
        if (scroller) scroller.scrollTop = 0;

        const cfg = ConfigService.cfg; // await ConfigService.LoadConfigOverride(this.patient.ward, patient);
        if (!cfg.roleViews || !this.definitionId) return;
        const roleConfig: IEncounterViewDefinition = cfg.roleViews.find(o => o.role?.toUpperCase() === UserService.UserRole?.toUpperCase());
        if (!roleConfig) return;

        try {
            RuntimeInfo.IsLoading = true;
            const configs: IEncounterViewDefinitionArrow[] = [];
            configs.push(...roleConfig.arrows);
            configs.push(...roleConfig.toolbar);

            this.viewDefinition = configs.find(o => o.uniqueId === this.definitionId);
            this.questionnaireNames = [...this.viewDefinition.questionnaires];

            await QuestionnaireService.Fetch();

            for (const name of this.questionnaireNames) {
                if (!this.questionnaires) this.questionnaires = [];

                const questionnaire = await QuestionnaireService.GetQuestionnaireByName(name, true);
                if (questionnaire) {
                    this.questionnaires.push(questionnaire);
                }
            }

            await this.loadLists();
        }
        catch (e) {
            console.warn(e);
        }
        finally {
            RuntimeInfo.IsLoading = false;
        }
        // get the groups from the questionnaires:
        /*
        this.multiformQuestionnaires = [];
        this.questionnaires = [];
        this.data = [];

        for (const qName of this.questionnaireNames) {
            const questionnaire: any = QuestionnaireService.GetQuestionnaireByNameDirect(qName);
            if (!questionnaire) {
                console.warn(`Questionnaire with name "${qName}" not found.`);
                continue;
            }

            this.questionnaires.push(<ExtendedQuestionnaire>questionnaire);
            if (!this.patient && this.encounterId)
                this.patient = await PatientItem.Load(this.encounterId);

            let questionnaireResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress])
            ; // || Tools.SubstituteDefaultQRSkeleton(this.patient, questionnaire.id, "in-progress");
            questionnaire['__isSelected'] = !!questionnaireResponse;

            if (!questionnaireResponse) continue;

            this.addQuestionnaire(<ExtendedQuestionnaire>questionnaire, undefined);
        }  */
    }

    listCode = 'documentlist';
    currentList :  any;

    selectedListIdChanged() {
        console.warn('selectedListIdChanged');

        for (const q of this.questionnaires)
            q.__isSelected = false;
        this.data = [];
        this.multiformQuestionnaires = [];

        if (!this.selectedListId || !this.lists?.length) return;
        const list = this.lists.find(o=>o.id === this.selectedListId);
        if (!list) return;

        this.currentList = list;
        for (const entry of list.entry.filter(o=>o.item?.reference)) {
            const responseId = NitTools.GetId(entry.item.reference);
            if (!responseId) continue;

            let response = this.patient.questionnaireResponses.find(o=>o.id === responseId);
            if (!response) continue;

            let questionnaire = QuestionnaireService.GetQuestionnaireDirect(response.questionnaire);
            if (!questionnaire) continue;

            const q = this.questionnaires.find(o=>o.id === questionnaire.id);
            if (!q) continue;

            q.__isSelected = true;
            this.addQuestionnaire(q, response);
        }
    }

    async saveList() {
        if (!this.currentList) {
            this.currentList = {
                id: NitTools.Uid(),
                mode: 'working',
                resourceType: 'List',
                status: 'current',
                title: 'Document List, created ' + new Date().toJSON(),
                date: new Date().toJSON(),
                encounter: {
                    reference: `Encounter/${this.encounterId}`
                },
                subject: {
                    reference: `Patient/${this.patient.id}`
                },
                source: {
                    reference: `Practitioner/${UserService.Practitioner.id}`
                },
                code: {
                    coding: [
                        {
                            code: this.listCode,
                            system: `${SystemHeaders.systemBase}documents/list`,
                            display: 'Document List'
                        },
                        {
                            code: this.definitionId,
                            system: `${SystemHeaders.systemBase}documents/view-definition-ud`,
                            display: "uniqueViewId: " + this.definitionId
                        }
                    ],
                    text: 'Document list'
                }
            }
        }

        let updateEntries : any[] = [this.currentList];
        this.currentList.entry = [];
        for (const d of this.data.filter(o=>o.selectedResponse)) {
            this.currentList.entry.push({
                date: new Date().toJSON(),
                deleted: false,
                item: {
                    reference: `QuestionnaireResponse/${d.selectedResponse.id}`
                }
            })

            updateEntries.push(d.selectedResponse);
        }

        console.warn(await this.fhirService.bundle(updateEntries, HTTPVerb.put));
    }

    lists : any[] = [];
    async loadLists() {
        const url = `List?encounter=${this.encounterId}&code=${this.listCode}&code=${this.definitionId}&_sort=-date`;
        this.lists = await this.fhirService.fetch(url);
        this.selectedListId =  (!this.lists?.length || !this.lists[0]) ? undefined : this.lists[0].id;
    }

    public async addNewList() {
        alert("Not Implemented!");
    }

    async removeLists() {
        await this.fhirService.bundle(this.lists, HTTPVerb.delete)
    }

    getElementTops() {
        this.positionData = [];
        const questionnaires = document.querySelectorAll('questionnaire[data-questionnaire-id]');
        questionnaires.forEach(questionnaire => {
            const qId = questionnaire.getAttribute('data-questionnaire-id');
            // header element:
            const qTop = NitTools.GetAbsoluteElementTop(document.querySelector(`.mf-questionnaire-header[data-questionnaire-id="${qId}"]`));
            const qHeight = $(questionnaire).height();
            $(questionnaire).data({top: qTop, height: qHeight});
            this.positionData.push({id: qId, type: 'questionnaire', top: qTop, height: qHeight});

            questionnaire.querySelectorAll('.questionnaire-group').forEach(group => {
                if ((<any>group).checkVisibility()) {
                    const gTop = NitTools.GetAbsoluteElementTop(<HTMLElement>group);
                    const gHeight = $(group).height();
                    $(group).data({top: gTop, height: gHeight});

                    this.positionData.push({id: group.getAttribute('id'), type: 'group', top: gTop, height: gHeight, bottom: gTop + gHeight, questionnaireId: qId});
                }
            })
        });
    }

    checkElementVisibility(): Element {
        if (this.isScrolling) return;

        const highlightGroup = function (pos) {
            $(`.mf-check-label[data-group-id="${pos.id}"], .mf-questionnaire-label[data-questionnaire-id="${pos.questionnaireId}"]`)
                .addClass('active');
        }

        const containerFluid = document.querySelector('.mf-questionnaires-parent');

        if (!containerFluid) {
            console.warn("Scroll container not found!");
            return;
        }

        const viewStart = containerFluid.scrollTop;
        const viewEnd = $(containerFluid).height() + viewStart;

        this.getElementTops();

        $('.mf-check-label, .mf-questionnaire-label').removeClass('active');

        if (viewStart <= 12) {
            const p = this.positionData.find(o => o.type === 'group');
            if (p) {
                highlightGroup(p);
                return;
            }
        }

        for (const pos of this.positionData) {
            if (pos.type !== 'group') continue;
            if (pos.bottom < viewStart || pos.top > viewEnd) continue; // next please, if bottom is above the top view border (scrolled out to top ) OR top out of bottom of view area
            if (
                (pos.top <= viewStart) // top of group is above the view top border
                || (pos.top > viewStart && pos.bottom < viewEnd) // small group, completely in view
                || (pos.top < viewEnd && pos.bottom > viewEnd) // large group, somewhere in the middle of the group
            ) // start of group under the bottom edge of the view
            {
                highlightGroup(pos);
            }
        }

        this.checkGroupsScrollPosition();
    }

    async attached() {
        await this.encounterIdChanged(this.encounterId);

        this.taskQueue.queueMicroTask(() => {
            document.querySelector('.mf-questionnaires-parent')?.addEventListener('scroll', () => this.checkElementVisibility());
            this.checkElementVisibility();
            window.setTimeout(() => this.readyForChanges = true, 500);
        });
    }

    async definitionIdChanged() {
        await this.loadData();
    }

    async patientChanged(patient) {
        if (!patient) return;
    }

    async encounterIdChanged(newVal) {
        this.patient = await PatientItem.Load(newVal);
        await this.loadData();
    }

    checkGroupsScrollPosition() {
        if (this.isScrolling) return;

        const label = document.querySelector('.mf-check-label.active');
        const menuContainer = document.querySelector('.mf-group-list-container');
        if (!label || !menuContainer) return;

        const containerTop = NitTools.GetAbsoluteElementTop(<HTMLElement>menuContainer);
        const height = $(menuContainer).height();
        const labelPosition = NitTools.GetAbsoluteElementTop(<HTMLElement>label) - containerTop;
        let scrollY = menuContainer.scrollTop;
        //let scrollHeight = menuContainer.scrollHeight;

        if (labelPosition > scrollY + height || labelPosition < scrollY) {
            this.isScrolling = true;
            let newPos = labelPosition;
            if (labelPosition < scrollY) {
                newPos -= height /2;
            }

            $(menuContainer).animate( { scrollTop : newPos }, () => {
                this.isScrolling = false;
            });
        }

    }

    scrollToGroup(questionnaireId, linkId) {
        this.getElementTops();
        const $scroll = $(".mf-questionnaires-parent"); // the container to scroll
        let target = this.positionData.find(o => o.id === linkId || o.id === 'group_' + linkId || o.id === questionnaireId);
        if (target) {
            this.isScrolling = true;
            $scroll.animate({scrollTop: target.top}, () => {
                this.isScrolling = false;
                this.checkElementVisibility();
            });
        }
    }
}

export interface IMultiformQuestionnaire {
    id: string;
    title: string;
    groups?: IMultiformQuestionnaireGroup[];
    hasGroups?: boolean;
    isNewResponse : boolean;
    hasChanges? : boolean;
}

export interface IMultiformQuestionnaireGroup {
    linkId: string;
    text: string;
    hint: string;
    isVisible: boolean;
}

export interface IMultiFormData {
    isNew : boolean;
    hasChanges? : boolean;
    questionnaire: any;
    responses: any[];
    selectedResponse: any;
}

export interface ExtendedQuestionnaire extends fhir4.Questionnaire {
    __isSelected: boolean;
}
