import {ConfigService} from "../../resources/services/ConfigService";
import {autoinject} from "aurelia-framework";
import {FhirService} from "../../resources/services/FhirService";
import {BasicForm} from "../../resources/elements/BasicForm";
import {ReportService} from "../../resources/services/ReportService";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {NitTools} from "../../resources/classes/NursitTools";
import {DialogService} from "aurelia-dialog";
import {ModalLooseImageEditDialog} from "../../resources/elements/modal-loose-image-edit-dialog";
import {DialogMessages} from "../../resources/services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {WoundDateEdit} from "./wunden/wound-date-edit";
import * as environment from "../../../config/environment.json";
import {UserService} from "../../resources/services/UserService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {woundImageDialog} from "./wunden/wound-image-dialog";
import {translations} from "../../resources/classes/translations";
import Viewer from "viewerjs";
import {PatientService} from "../../resources/services/PatientService";
import {Router} from "aurelia-router";
import {WoundDataHandler} from "./wunden/wound-data-handler";
import {assignWoundDialogContent} from "../../resources/elements/wounds/assign-wound-dialog-content";
import ToolbarOptions = Viewer.ToolbarOptions;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

const moment = require('moment');

@autoinject

export class OtherImages {
    patientId: string;
    otherImages: MediaItem[] = [];
    doctorImages: MediaItem[] = [];
    displayImage: HTMLImageElement;
    showDoctorsOrders: boolean = true;

    protected fhirService: FhirService;
    constructor(protected i18n: I18N,
        protected router: Router,
        protected dialogService: DialogService, protected dialogMessage: DialogMessages
    ) {
        this.fhirService = new FhirService(FhirService.Endpoint);
    }

    /* PATCH:
see: https://smilecdr.com/docs/fhir_repository/updating_data.html
    */
    activate(params) {
        this.patientId = params.patientId;

        if (typeof ConfigService.cfg?.features?.showDoctorsOrders === 'boolean') {
            this.showDoctorsOrders = ConfigService.cfg.features.showDoctorsOrders;
        }
        
        if (ConfigService.Debug) window['images'] = this;
    }

    async loadImages() {
        const subTypeName = FhirService.FhirVersion === 3 ? 'subtype' : 'modality';
        const urlUnbound = `Media?_format=json&patient=${this.patientId}&${subTypeName}=unbound-image&${subTypeName}=thumbnail`;
        const urlDoctor = `Media?_format=json&patient=${this.patientId}&${subTypeName}=order-image&${subTypeName}=thumbnail`;

        this.otherImages = [];
        let medias = <any[]>await this.fhirService.fetch(urlUnbound);
        for (const media of medias.filter(o => o.identifier)) {
            const mi = new MediaItem();
            mi.media = media;
            this.otherImages.push(mi);
        }

        this.doctorImages = [];
        medias = <any[]>await this.fhirService.fetch(urlDoctor);
        for (const media of medias.filter(o => o.identifier)) {
            const mi = new MediaItem();
            mi.media = media;
            this.doctorImages.push(mi);
        }
    }

    async openWoundAssignDialog(item: MediaItem) {
        let mediaLarge: any;
        if (item.media.identifier) {
            const ident = item.media.identifier.find(o => o.system.endsWith('media-source-id'));
            if (ident) {
                const mediaLargeUrl = `Media/${item.media.id}?_summary=true`;
                mediaLarge = <any>await this.fhirService.get(mediaLargeUrl);
            }
        }

        const wd = new WoundDataHandler(this.i18n);
        this.dialogService.open({
            viewModel: assignWoundDialogContent,
            model: { handler: wd, encounterId: PatientItem.SelectedPatient.encounterId }
        })
            .whenClosed(async result => {
                if (!result.wasCancelled && result.output) {
                    const toUpdate = [];
                    if (!item.media.identifier) item.media.identifier = [];
                    let existing = item.media.identifier.find(o => o.system.endsWith('/observation-id'));
                    if (!existing) {
                        existing = { system: `${NitTools.ExcludeTrailingSlash(environment.systemHeader)}/observation-id` }
                        item.media.identifier.push(existing);
                    }

                    // remove the unbound sub-type
                    const subTypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';
                    if (item.media[subTypeName]?.coding) {
                        item.media[subTypeName].coding = item.media[subTypeName].coding.filter(o => o.code !== 'unbound-image');
                    }

                    existing.value = result.output;
                    toUpdate.push(item.media);

                    if (mediaLarge) {
                        if (!mediaLarge.identifier) mediaLarge.identifier = [];
                        let existing = mediaLarge.identifier.find(o => o.system.endsWith('/observation-id'));
                        if (!existing) {
                            existing = { system: `${NitTools.ExcludeTrailingSlash(environment.systemHeader)}/observation-id` }
                            mediaLarge.identifier.push(existing);
                        }

                        existing.value = result.output;
                        toUpdate.push(mediaLarge);
                    }

                    await this.fhirService.bundle(toUpdate, HTTPVerb.put, BundleType.transaction, false);
                }
            })
    }

    printButtonClicked() {
        ReportService.Preview('-', "other_images.frx");
        /*const url = `${ReportService.BaseUrl}/api/data/Media/${PatientItem.SelectedPatient.id}`;
        window.open(url, '_reportPreview'); */
    }

    askDelete(media: any) {
        this.dialogMessage.dialog("Wollen Sie dieses Bild wirklich löschen?", this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"), true)
            .whenClosed(async result => {
                if (!result.wasCancelled) {
                    const ident = media.identifier.find(o => o.system.endsWith('media-source-id'));
                    if (ident && ident.value) {
                        const deleteMediaId = ident.value;
                        await this.fhirService.deleteUrl(`Media/${deleteMediaId}`);
                    }

                    this.otherImages = this.otherImages.filter(o => o.media.id !== media.id);
                    await this.fhirService.delete(media);
                }
            })
    }

    async updateMedia(text: string, print: boolean, media: any) {
        try {
            RuntimeInfo.IsLoading = true;

            if (!media.identifier) media.identifier = [];
            let ident: any = media.identifier.find(o => o.system.endsWith("PrintImage"));
            if (!ident) {
                ident = {
                    system: 'http://nursit-institute.com/fhir/StructureDefinition/PrintImage'
                };

                media.identifier.push(ident);
            }

            if (media.content != null) {
                media.content.title = text;
            }

            ident.value = `${print}`;
            let mediaLarge: any;

            if (media.identifier) {
                const ident = media.identifier.find(o => o.system.endsWith('media-source-id'));
                if (ident) {
                    const mediaLargeUrl = `Media/${ident.value}`;
                    mediaLarge = <any>await this.fhirService.get(mediaLargeUrl);

                    await this.fhirService.tags.update(mediaLarge, { system: "http://nursit-institute.com/fhir/StructureDefinition/PrintImage", code: print ? "true" : "false" });
                    await this.fhirService.tags.update(media, { system: "http://nursit-institute.com/fhir/StructureDefinition/PrintImage", code: print ? "true" : "false" });
                }
            }

            media.text = {
                status: 'generated',
                div: `<div xmlns="http://www.w3.org/1999/xhtml">${text}</div>`
            };

            if (mediaLarge) {
                mediaLarge.text = media.text;

                if (mediaLarge.content) {
                    mediaLarge.content.title = text;
                }
            }

            await this.fhirService.bundle([media, mediaLarge], HTTPVerb.put, BundleType.transaction, false);

            const item = this.otherImages.find(o => o.media.id === media.id);
            if (item) {
                item.media = media;
            }
        } catch (e) {
            console.warn(e);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async printImage(media: any) {
        const w = window.open("about:blank");
        w.document.body.innerHTML = `<body><h1>${this.i18n.tr("loading")}</h1></body>`;

        if (media.identifier) {
            let ident = media.identifier.find(o => o.system.endsWith('media-source-id'));
            if (!ident) return;
            const targetMediaId = ident.value;

            if (targetMediaId && targetMediaId !== media.id) {
                media = <any>await this.fhirService.get('Media/' + targetMediaId);
            }
        }

        let creation = moment(media.content.creation).format(RuntimeInfo.DateTimeFormat);
        let title = media.content.title;
        // `data:image/jpeg;base64,${media.content.data}`
        w.document.body.innerHTML = `<body style="font-family: Arial, Helvetica, sans-serif;">
                    <label style="font-weight: bold; position: absolute; left:8px; top: 0">${PatientItem.LastLoadedPatient.display}</label>
                    <label style="position: absolute; right:8px; top: 0">${creation}</label>                    
                    <label>&nbsp;</label><br /><label style="margin-top: 1.25em">${title}</label><br />
                    <img alt="${media.content.title}" style="margin-top:1em; width:100%; height: auto" src="data:image/jpeg;base64,${media.content.data}">
                   </body>`;
        window.setTimeout(() => w.print(), 1000);
    }

    editImage(media: any) {
        this.dialogService.open({
            viewModel: ModalLooseImageEditDialog,
            model: media
        }).whenClosed(async (result) => {
            if (!result.wasCancelled) {
                if (result.output.delete === true) {
                    this.askDelete(result.output.media);
                } else {
                    await this.updateMedia(result.output.text, result.output.print, result.output.media);
                }
            }
        })
            .catch((error) => {
                console.warn(error);
            })
    }

    fileUpload: HTMLInputElement;

    getBase64(file): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.toString());
            reader.onerror = error => reject(error);
        });
    }

    orientation(file): Promise<number> {
        return new Promise<number>((resolve) => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                //let base64img = "data:" + file.type + ";base64," + this._arrayBufferToBase64(fileReader.result);
                let scanner = new DataView(<ArrayBuffer>fileReader.result);
                let idx = 0;
                let value = 1; // Non-rotated is the default
                if ((<ArrayBuffer>fileReader.result).byteLength < 2 || scanner.getUint16(idx) != 0xFFD8) {
                    // Not a JPEG
                    resolve(value);
                    return;
                }

                idx += 2;
                let maxBytes = scanner.byteLength;
                while (idx < maxBytes - 2) {
                    let uint16 = scanner.getUint16(idx);
                    idx += 2;
                    switch (uint16) {
                        case 0xFFE1: // Start of EXIF
                            let exifLength = scanner.getUint16(idx);
                            maxBytes = exifLength - idx;
                            idx += 2;
                            break;
                        case 0x0112: // Orientation tag
                            // Read the value, its 6 bytes further out
                            // See page 102 at the following URL
                            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
                            value = scanner.getUint16(idx + 6, false);
                            maxBytes = 0; // Stop scanning
                            break;
                    }
                }

                resolve(value);
            };

            fileReader.readAsArrayBuffer(file);
        })
    }

    async processImageFile() {
        try {
            let files: FileList = this.fileUpload.files;
            let numFiles = files ? files.length : 0;
            if (!this.fileUpload.value || numFiles === 0) return;

            let label = this.fileUpload.value.replace(/\\/g, '/').replace(/.*\//, '').replace('.jpg', '').replace('.JPG', '');

            let authored = new Date();
            try {
                if (this.fileUpload.files[0]) {
                    if (this.fileUpload.files[0]["lastModifiedDate"]) {
                        authored = <Date>this.fileUpload.files[0]["lastModifiedDate"];
                    } else if (this.fileUpload.files[0]["lastModified"]) {
                        authored = new Date(this.fileUpload.files[0]["lastModified"])
                    }
                }
            } catch (err) {
                console.warn(err.message);
                authored = new Date();
            }

            this.dialogService.open({
                viewModel: WoundDateEdit,
                model: {
                    date: authored
                }
            })
                .whenClosed(async result => {
                    if (result.wasCancelled) {
                        this.fileUpload.value = '';
                        return;
                    } else {
                        try {
                            RuntimeInfo.IsLoading = true;
                            let base64String: string = await this.getBase64(files[0]);
                            base64String = base64String.substr(base64String.indexOf(',') + 1);
                            let angle = await this.orientation(files[0]);
                            await this.uploadFile(label, new Date(result.output.date), base64String, angle, files[0].type);
                            await this.reloadImages();
                        } finally {
                            RuntimeInfo.IsLoading = false;
                            this.fileUpload.value = '';
                        }
                    }
                })
        } catch (error) {
            console.warn(error.message);
            this.dialogMessage.prompt('Error when getting File Infos', 'Error when Uploading', true);
        }
    }

    _getIcon(source): Promise<string> {
        return new Promise<string>((resolve) => {
            let img = document.createElement("img");
            img.style.display = "block";

            img.onload = () => {
                let cnv = document.createElement("canvas");
                cnv.width = 100;
                cnv.height = 100;

                let f;
                ////////////////////
                if (img.width > img.height || img.width === img.height) {
                    f = img.width / cnv.width;
                } else {
                    f = img.height / cnv.height;
                }

                let dw = img.width / f;
                let dh = img.height / f;
                let tx = (cnv.width / 2) - (dw / 2);
                let ty = (cnv.height / 2) - (dh / 2);
                if (tx < 0) tx = 0;
                if (ty < 0) ty = 0;
                let ctx = cnv.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height, tx, ty, dw, dh);

                resolve(cnv.toDataURL("image/png"))
            };

            if (source.indexOf("data:image/") === -1) source = "data:image/jpg;base64," + source;
            img.src = source;
        });
    }

    async uploadFile(label: string, authored: Date, base64String: string, angle: number, type: string) {
        try {
            /* let observationIdentifier = {
                system: `${NitTools.ExcludeTrailingSlash(environment.systemHeader)}/observation-id`,
                value: "free"
            }; */

            const printId = `${NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition)}PrintImage`;

            /* const freeIdentifier = {
                system: "http://nursit-institute.com/fhir/StructureDefinition/image-type",
                value: "unbound-image"
            }; */

            let printIdentifier : any = { system: printId, use: "official", value: "no" };
            let operator = {
                reference: 'Practitioner/' + UserService.Practitioner.id,
                display: UserService.UserLastName + (UserService.UserFirstName ? ', ' + UserService.UserFirstName : '')
            };

            let image: any = {
                resourceType: fhirEnums.ResourceType.media,
                id: NitTools.Uid(),
                operator: operator,
                text: {
                    status: "generated",
                    div: `<div xmlns="http://www.w3.org/1999/xhtml">${label}</div>`
                },
                identifier: [
                    printIdentifier,
                    //     observationIdentifier,
                    //     freeIdentifier
                ],
                type: fhirEnums.DigitalMediaType.photo,
                subject: {
                    reference: fhirEnums.ResourceType.patient + "/" + PatientItem.SelectedPatient.id
                },
                device: {
                    display: RuntimeInfo.IsMobile ? "IPad" : "Computer"
                },
                frames: 1,
                content: {
                    id: NitTools.Uid(),
                    contentType: type,
                    data: base64String,
                    creation: authored.toJSON(),
                    title: label
                }
            };

            let encounterName = 'context';
            let subtypeName = 'subtype';
            if (FhirService.FhirVersion > 3) {
                encounterName = 'encounter';
                subtypeName = 'modality';

                image['status'] = 'completed';
            }

            image[encounterName] = {reference: `Encounter/${PatientItem.SelectedPatient.encounterId}` };
            image[subtypeName] = { coding: [] };
            if (this.subType === 'unbound-image') {
                image[subtypeName] = {
                    coding: [
                        {
                            system: "http://snomed.info/sct",
                            code: "103734008"  // External ocular photography for medical evaluation and documentation
                        }
                    ]
                }
            }

            image[subtypeName].coding.push({
                system: "http://nursit-institute.com/fhir/StructureDefinition/image-type",
                code: this.subType
            });

            let thumb: any = <any>NitTools.Clone(image);
            thumb.content.data = await this._getIcon(thumb.content.data);
            if (thumb.content.data.indexOf(';') > -1)
                thumb.content.data = thumb.content.data.split(';')[1];
            if (thumb.content.data.indexOf('base64,') === 0)
                thumb.content.data = thumb.content.data.substring(7);

            thumb[subtypeName].coding = [
                {
                    "code": "thumbnail",
                    "system": 'http://nursit-institute.com/fhir/StructureDefinition/image-type'
                },
                {
                    "system": 'http://nursit-institute.com/fhir/StructureDefinition/image-type',
                    "code": this.subType
                }
            ];

            image.id = NitTools.Uid();
            thumb.id = NitTools.Uid();

            // link the two images by identifier:
            thumb.identifier.push({
                system: NitTools.ExcludeTrailingSlash(environment.systemHeader) + '/media-source-id',
                value: image.id
            })

            image.identifier.push({
                system: NitTools.ExcludeTrailingSlash(environment.systemHeader) + '/media-thumbnail-id',
                value: thumb.id
            });

            if (FhirService.FhirVersion > 3)
                thumb['status'] = 'completed';

            await this.fhirService.bundle([image, thumb], HTTPVerb.put, BundleType.transaction);

            await this.loadImages();
            this.fileUpload.value = "";
        } catch (e) {
            console.warn(e.message);
            alert(e.message);
        }
    }

    async openImageUrl(media: any) {
        let targetMediaId = media.id;

        if (media.identifier) {
            let ident = media.identifier.find(o => o.system.endsWith('media-source-id'));
            if (ident != null)
                targetMediaId = ident.value;

            if (targetMediaId && targetMediaId !== media.id) {
                media = <any>await this.fhirService.get('Media/' + targetMediaId);
            }
        }

        const drawingUrl = 'Media?identifier=http://nursit-institute.com/fhir/StructureDefinition/image-objects-target|' + targetMediaId;
        const drawingMedias = await this.fhirService.fetch(drawingUrl);
        let drawingObjects = {};
        try {
            if (drawingMedias && drawingMedias[0]) {
                const drawingMedia = <any>drawingMedias[0];
                drawingObjects = JSON.parse(atob(drawingMedia.content.data));
            }
        } catch (EX) {
            console.warn(EX);
            drawingObjects = null;
        }

        this.dialogService.open({
            viewModel: woundImageDialog,
            model: {
                imageSource: (<any>media.content).data,
                title: translations.translate('information'),
                media: <any>media,
                thumbnail: null,
                mediaId: media.id,
                drawingObjects: drawingObjects,
                showDraw: true,
                onOpenDraw: () => {
                    this.router.navigateToRoute('draw', { id: targetMediaId, encounter: PatientService.LatestPatient.encounterId });
                }
            },
            lock: true,
        })
            .then(() => {
                let img: HTMLImageElement = document.querySelector(".touch-image");
                img.style.display = "none";

                let optionShow: any = { show: true, size: 'large' };
                let options: ToolbarOptions = {
                    prev: false, play: false, next: false, oneToOne: false,
                    flipHorizontal: optionShow, flipVertical: optionShow, reset: optionShow, rotateLeft: optionShow,
                    rotateRight: optionShow, zoomIn: optionShow, zoomOut: optionShow
                };

                this.viewer = new Viewer(img, {
                    inline: true,
                    fullscreen: false,
                    navbar: false,
                    transition: false,
                    title: false,
                    minZoomRatio: 0.5,
                    maxZoomRatio: 5,
                    toolbar: options
                });

                this.viewer.view();
            })
    }

    viewer;

    private subType: string;
    uploadNewImage(subType: string) {
        this.subType = subType;
        $(this.fileUpload).trigger("click");
    }

    reloadImages() {
        this.otherImages = [];
        RuntimeInfo.IsLoading = true;
        this.loadImages()
            .catch(err => console.warn(err))
            .finally(() => RuntimeInfo.IsLoading = false);
    }

    attached() {
        RuntimeInfo.IsLoading = true;
        BasicForm.pageTitle = "Bilddokumentation";
        this.reloadImages();
    }
}


export class MediaItem {
    _media: any;
    public print: boolean;
    public text: string;

    public get media(): any {
        return this._media;
    }

    public set media(value: any) {
        this._media = value;

        if (!this._media.identifier) this._media.identifier = [];
        let ident: any = this._media.identifier.find(o => o.system.endsWith("PrintImage"));
        if (!ident) {
            ident = {
                system: 'http://nursit-institute.com/fhir/StructureDefinition/PrintImage',
                value: "false"
            };

            this._media.identifier.push(ident);
        }

        this.print = ident.value == "true";

        if (!this._media.note) this._media.note = [];

        const subtypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';
        if (this._media.note.length === 0 && this.media[subtypeName]?.coding && typeof (this.media[subtypeName].coding.find(o => o.code === 'order-image')) !== "undefined")
        {
            this.text = moment(this._media.occurrenceDateTime).format(RuntimeInfo.DateTimeFormat);
            this.media.note = [{ text: this.text }];
        }

        let note: any = this._media.note[0];
        if (!note) {
            note = { text: '' };
            this._media.note.push(note);
        }

        this.text = note.text;
        if (!this.text && this._media.text && this._media.text.div) {
            const tmp = this._media.text.div;
            this.text = tmp.replace(/<.*?>/g, "");
        }
    }
}
